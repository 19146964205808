import React, { useContext, useState } from 'react'
import '../../App.css'
import CSVReader from 'react-csv-reader'

import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'

import Window from '../../components/Window'

function ImportSettingsScreen({settings, setSettings}) {

    const { setLoading } = useContext(AppContext)

    const [mcBkImport, setMcBkImport] = useState(null)
    const [mcPbImport, setMcPbImport] = useState(null)
    const [mcContactImport, setMcContactImport] = useState(null)

    function manacareBKImport(){

        if(mcBkImport === null) return

        setLoading(true)
        
        authAxios.post('import/manacare/bk', {
            import: mcBkImport,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                console.log(response.data)
                alert(response.data.importCount + ' Benutzer importiert!')
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    function manacarePBImport(){

        if(mcPbImport === null) return

        setLoading(true)
        
        authAxios.post('import/manacare/pb', {
            import: mcPbImport,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                console.log(response.data)
                alert(response.data.importCount + ' Benutzer importiert!')
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    function manacareContactImport(){

        if(mcContactImport === null) return

        setLoading(true)
        
        authAxios.post('import/manacare/contact', {
            import: mcContactImport,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                console.log(response.data)
                alert(response.data.importCount + ' Kontakte importiert!')
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    
    function exportCleverreachBK(){

        setLoading(true)

        authAxios.get('export/cleverreach/bk', { responseType: 'blob', timeout: 30000})
        .then(response => {

            setLoading(false)
            
            let link = document.createElement('a')
            link.href = URL.createObjectURL(response.data)
            link.setAttribute('download', 'BK_Export')
            document.body.appendChild(link)
            link.click()

        })
        .catch(error => {
            console.log(error.response)
        })

    }

    function exportCleverreachPB(){

        setLoading(true)

        authAxios.get('export/cleverreach/pb', { responseType: 'blob', timeout: 30000})
        .then(response => {

            setLoading(false)
            
            let link = document.createElement('a')
            link.href = URL.createObjectURL(response.data)
            link.setAttribute('download', 'PB_Export')
            document.body.appendChild(link)
            link.click()

        })
        .catch(error => {
            console.log(error.response)
        })

    }

    return (
        <div className="page-content">

            <div className="window-row">

                <div className="col">

                    <Window name="settingsUserExport" title="User Export (Cleverreach)">                        
                        <div className="container">

                            <button onClick={exportCleverreachBK}>Betreuungskräfte exportieren</button>

                            <br /><br />

                            <button onClick={exportCleverreachPB}>Kunden inkl. Kontakte exportieren</button>

                        </div>
                    </Window>

                </div>
                <div className="col">

                    <Window name="settingsManacareImport" title="Manacare Import">                        
                        <div className="container">

                            <div className="row">
                                <div className="col">
                                    <CSVReader
                                    label="Betreuungskräfte CSV auswählen:"
                                    onFileLoaded={(data, fileInfo) => setMcBkImport(data)}
                                    parserOptions={{
                                        header: true,
                                        dynamicTyping: true,
                                        skipEmptyLines: true,
                                        transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
                                    }} />
                                </div>
                                <div className="col">
                                    <button style={{marginTop: 25, height: 40}} onClick={() => manacareBKImport()}>Importieren</button>
                                </div>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col">
                                    <CSVReader
                                    label="Kunden CSV auswählen:"
                                    onFileLoaded={(data, fileInfo) => setMcPbImport(data)}
                                    parserOptions={{
                                        header: true,
                                        dynamicTyping: true,
                                        skipEmptyLines: true,
                                        transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
                                    }} />
                                </div>
                                <div className="col">
                                    <button style={{marginTop: 25, height: 40}} onClick={() => manacarePBImport()}>Importieren</button>
                                </div>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col">
                                    <CSVReader
                                    label="Kontakte CSV auswählen:"
                                    onFileLoaded={(data, fileInfo) => setMcContactImport(data)}
                                    parserOptions={{
                                        header: true,
                                        dynamicTyping: true,
                                        skipEmptyLines: true,
                                        transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
                                    }} />
                                </div>
                                <div className="col">
                                    <button style={{marginTop: 25, height: 40}} onClick={() => manacareContactImport()}>Importieren</button>
                                </div>
                            </div>

                        </div>
                    </Window>

                </div>

            </div>

        </div>
    )

}

export default ImportSettingsScreen
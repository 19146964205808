import React, { useEffect, useState } from 'react'
import '../App.css'
import { authAxios } from '../scripts/Axios'

function Video({file}) {

    const [url, setUrl] = useState('')
    const classNames = 'media-full ' + file.kategorie

    useEffect(() => {

        let loadVideo = async () => {
            let endpoint = '/upload'
            let video = await authAxios.get(endpoint  + '/' + file.id, {responseType: 'blob', timeout: 60000})
            if(video.status === 200){
                let u = URL.createObjectURL(video.data)
                setUrl(u)
            }
        }
        loadVideo()

    }, [])

    return url ? (       
        <video controls className={classNames}>
            <source src={url} type={file.typ} />
        </video>
    ) : <svg className="spinner" viewBox="0 0 50 50"><circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle></svg>

}

export default Video
import React, { useContext, useState, useEffect } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { authAxios } from '../scripts/Axios'

import Window from '../components/Window'
import Alert from '../components/Alert'
import ProfileImage from '../components/ProfileImage'

import { AppContext } from '../scripts/AppContext'
import { DataContext } from '../scripts/DataContext'
import { UserContext } from '../scripts/UserContext'


function AdminProfileScreen() {

    const { setLoading, reloadProfileImage, setReloadProfileImage } = useContext(AppContext)
    const { adminRoles } = useContext(DataContext)
    const { user, setUser } = useContext(UserContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [editInputs, setEditInputs] = useState({
        password: '',
        password_confirmation: '',
    })


    function updatePassword(){
        setLoading(true)
        
        authAxios.post('admin-profile-password', editInputs)
        .then(response => {

            setLoading(false)
            setAlertMessage({ type: response.data.type, message: response.data.message })

            if(response.data.type !== 'error'){
                setEditInputs({
                    password: '',
                    password_confirmation: '',
                })
            }

        })
        .catch(error => {
            console.log(error)
        })
    }


    function handleUpload(files){

        setLoading(true)

        let file = files[0]
        let data = new FormData()
        data.append('image', file)
        
        authAxios.post('admin-profile-upload', data)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUser(prev => ({
                    ...user,
                    profile_image: response.data.image,
                }))
                setReloadProfileImage(Math.random())
            }

        })
        .catch(error => {
            console.log(error)
        })

    }


    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <Window name="adminProfileData" title="Profildaten">
                    <div className="container">

                        <div className="row" style={{ gap: 30 }}>
                            <div className="col">

                                <div className="subtitle">Stammdaten</div>
                                <div className="profile-table">
                                    <div className="row">
                                        <div className="col">Vorname:</div>
                                        <div className="col">{user.vorname}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Nachname:</div>
                                        <div className="col">{user.nachname}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">E-Mail:</div>
                                        <div className="col">{user.email}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Benutzerrolle:</div>
                                        <div className="col">{adminRoles.find(r => r.id == user.role).name}</div>
                                    </div>
                                </div>

                                <br /><br />
                                
                                <div className="subtitle">Passwort ändern</div>
                                <div className="row">
                                    <div className="col">
                                        <label>Neues Passwort<br />
                                            <input type="password" autoComplete="new-password" value={editInputs.password} onChange={e => setEditInputs({...editInputs, password: e.target.value})} />
                                        </label>
                                    </div>
                                    <div className="col">
                                        <label>Neues Passwort wiederholen<br />
                                            <input type="password" autoComplete="new-password" value={editInputs.password_confirmation} onChange={e => setEditInputs({...editInputs, password_confirmation: e.target.value})} />
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small>Passwortanforderungen: min. 8 Zeichen, davon min. 1 Buchstabe, 1 Zahl und 1 Sonderzeichen</small>
                                    </div>
                                    <div className="col auto">
                                        <button onClick={updatePassword}>Passwort ändern</button>
                                    </div>
                                </div>
                        
                            </div>
                            <div className="col">
                                
                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col">

                                        <div className="subtitle">Profilbild</div>
                                        <ProfileImage id={user.id} reload={reloadProfileImage} style={{ width: 280, height: 280, objectFit: 'cover' }} />
                                        <div className="uploader" style={{ maxWidth: 280 }}>                      
                                            <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                                            {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <button style={{ width: 280, marginTop: 1 }}><FontAwesomeIcon icon={faUpload} /> Profilbild ändern</button>
                                                </div>
                                            )}
                                            </Dropzone>
                                        </div>

                                    </div>
                                    <div className="col"></div>
                                </div>

                            </div>
                        </div>


                    </div>
                </Window>

            </div>
        </Scrollbars>
    )

}

export default AdminProfileScreen
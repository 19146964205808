import React, { useContext, useRef, useEffect } from 'react'
import { DataContext } from './DataContext'
import { authAxios } from './Axios'


export const bkContracts = [
    { label: 'AV Polnisch', value: 'avp' },
    { label: 'AV Deutsch', value: 'avd' },
    { label: 'AV Kombi', value: 'avk' },
]
export const pbContracts = [
    { label: 'Deutscher Pflegedienstvertrag', value: 'dpfv' },
    { label: 'Polnischer Dienstleistungsvertrag', value: 'pv' },
    { label: 'Deutscher & Polnischer Vertrag', value: 'd+pv' },
]
    
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay])
}


export const ProfileField = (id) => {
    
    const { profileOptions } = useContext(DataContext)

    if(id){
        if(Array.isArray(id)){
            return id.map(i => {
                let option = profileOptions.find(opt => opt.id === i)
                return (
                    <div key={i} className="profileField">
                        { option.label }
                    </div>
                )
            })
        }else{
            let option = profileOptions.find(opt => opt.id === id)
            return (
                <div className="profileField">
                    { option.label }
                </div>
            )
        }
    }

}


export const ContractType = ({ contract }) => {

    switch(contract){
        case 'dpfv': return <span className="bold"><span style={{ color: '#000' }}>DP</span><span style={{ color: '#e30613' }}>F</span><span style={{ color: '#fdc647' }}>V</span></span>
        case 'pv': return <span className="bold"><span style={{ color: '#dd328a' }}>PV</span></span>
        case 'd+pv': return <span className="bold"><span style={{ color: '#000' }}>D+</span><span style={{ color: '#dd328a' }}>PV</span></span>
        case 'avp': return <span className="bold"><span style={{ color: '#dd328a' }}>AVP</span></span>
        case 'avd': return <span className="bold"><span style={{ color: '#000' }}>A</span><span style={{ color: '#e30613' }}>V</span><span style={{ color: '#fdc647' }}>D</span></span>
        case 'avk': return <span className="bold"><span style={{ color: '#000' }}>AVK</span></span>
        default: return <span>-</span>
    }

}


export const LanguageBar = ({ value }) => {

    let width = value * 10
    let color = '#2fc08c'

    if(value){
        if(value <= 3.5) color = '#e5614f'
        else if(value > 3.5 && value <= 5) color = '#f1a930'
    }
    else width = 0

    return (
        <div className="languageBar">
            <div style={{ width: width + '%', backgroundColor: color }}>
                <span>{ value }</span>
            </div>
        </div>
    )

}


export const ContactType = (ids) => {
    
    const { contactTypes } = useContext(DataContext)
    
    let types = []
    if(ids && ids.length){
        ids.map((id, index) => {
            let ct = contactTypes.find(el => el.id === id)
            if(ct) types.push(ct.name)
        })
    } else{
        let ct = contactTypes.find(el => el.id === ids)        
        if(ct) types.push(ct.name)
    }

    return types.join(', ')

}


export const SizeFormat = (bytes, dp = 1) => {
    
    const thresh = 1024

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }

    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1
    const r = 10**dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    if(units[u] === 'KB'){
        dp = 0
    }

    return bytes.toFixed(dp) + ' ' + units[u]

}

export function useOutsideClick(ref, callback) {

    useEffect(() => {
        function handleClickOutside(event) {
            if(ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])
    
}

export const Country = ({ iso, align = 'start' }) => {

    const { countries } = useContext(DataContext)
    
    return iso ? (
        <div style={{ display: 'flex', gap: 5, justifyContent: align }}>
            <img src={'http://purecatamphetamine.github.io/country-flag-icons/3x2/' + iso + '.svg'} style={{ width: 22 }} />
            <span>{ countries[iso] }</span>
        </div>
    ) : null

}

export function updatePref(slug, options){

    authAxios.put('prefs', {
        slug: slug,
        options: options,
    })

}
   
export async function getPref(slug){

    let response = await authAxios.get('prefs/' + slug)
    return response.data.options

}

export function updateLocalPref(slug, options){   

    localStorage.setItem(slug, JSON.stringify(options));

}
   
export function getLocalPref(slug){

    let options = localStorage.getItem(slug)
    return options ? JSON.parse(options) : null

}
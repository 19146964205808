import React, { useEffect, useState, useContext } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router-dom'
import { authAxios } from '../scripts/Axios'
import { AppContext } from '../scripts/AppContext'

import Window from '../components/Window'
import Table from '../components/Table'

function TranslationScreen() {

    const history = useHistory()
    const { setLoading } = useContext(AppContext)

    const [translationUsers, setTranslationUsers] = useState([])

    useEffect(() => {

        setLoading(true)

        authAxios.get('translation-users')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setTranslationUsers(response.data.users)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }, [])

    function handleEditUser(id){
        history.push('/translate/' + id)
    }


    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <div className="window-row">

                    <div className="col">

                        <Window name="translationListDE" title="Deutsche Übersetzungen">

                            <Table name="tableGermanTranslation" data={translationUsers.filter(user => user.user_role === 2)} headers={[
                                {
                                    label: 'Datum',
                                    field: 'created_at',
                                    type: 'datetime',
                                    size: 'm',
                                },
                                {
                                    label: 'Name',
                                    field: 'id',
                                    type: 'user',
                                    size: 'xl',
                                },
                            ]} onEdit={handleEditUser} editText="Übersetzen" />

                        </Window>

                    </div>

                    <div className="col">

                        <Window name="translationListPL" title="Polnische Übersetzungen">

                            <Table name="tablePolishTranslation" data={translationUsers.filter(user => user.user_role === 1)} headers={[
                                {
                                    field: 'created_at',
                                    label: 'Datum',
                                    type: 'datetime',
                                    size: 'm',
                                },
                                {
                                    label: 'Name',
                                    field: 'id',
                                    type: 'user',
                                    size: 'xl',
                                },
                            ]} onEdit={handleEditUser} editText="Übersetzen" />

                        </Window>

                    </div>

                </div>

            </div>
        </Scrollbars>
    )

}

export default TranslationScreen
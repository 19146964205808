import React, { useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import Media from '../components/Media'
import Video from '../components/Video'

function Lightbox({files, index, onClose, type = "upload"}) {

    const [slide, setSlide] = useState(index)

    return ( 
        <div className="lightbox">

            <div className="lightbox-bg" onClick={onClose}></div>
            <div className="lightbox-close" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
            </div>

            { slide-1 >= 0 &&
                <div className="lightbox-prev" onClick={() => setSlide(slide-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
            }

            { files[slide].typ.startsWith('image') &&
                <Media key={slide} file={files[slide]} flex={'0 0 50%'} square={false} type={type} className="noselect" />
            }

            { files[slide].typ.startsWith('video') &&
                <Video key={slide} file={files[slide]} className="noselect" />
            }

            { slide+1 < files.length &&
                <div className="lightbox-next" onClick={() => setSlide(slide+1)}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
            }

        </div>
    )

}

export default Lightbox
import React, { useEffect, useState, useContext } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { authAxios } from '../scripts/Axios'

import { AppContext } from '../scripts/AppContext'

import Window from '../components/Window'
import Table from '../components/Table'
import Alert from '../components/Alert'

function AppScreen() {

    const { setLoading } = useContext(AppContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [requests, setRequests] = useState(null)

    useEffect(() => {

        setLoading(true)
        
        authAxios.get('requests')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setRequests(response.data.requests)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })


    }, [])
    
    
    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <Window name="appRequests" title="Anfragen der Betreuungskräfte">

                    <Table name="tableRequests" data={requests} headers={[
                        {
                            field: 'status',
                            label: 'Status',
                            type: 'requeststatus',
                            size: 's',
                        },
                        {
                            field: 'created_at',
                            label: 'Datum',
                            type: 'datetime',
                            size: 'm',
                        },
                        {
                            field: 'user_id',
                            label: 'Betreuungkraft',
                            type: 'user',
                            size: 'l',
                        },
                        {
                            field: 'pb_user_id',
                            label: 'Plegebedürftige Person',
                            type: 'user',
                            size: 'l',
                        },
                        {
                            field: 'remembered_at',
                            label: 'Letzte Erinnerung an Kunde',
                            type: 'datetime',
                            size: 'm',
                        },
                    ]} />

                </Window>

            </div>
        </Scrollbars>
    )

}

export default AppScreen
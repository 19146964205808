import React, { useEffect, useState } from 'react'
import '../App.css'

function Alert({ data }) {

    const types = {
        error: '#E5614F',
        info: '#f1a930',
        success: '#2FC08C',
    }

    const [alert, setAlert] = useState(data)

    useEffect(() => {
        if(!data) return
        setAlert(data)
        setTimeout(() => {
            setAlert(null)
        }, 3000)
    }, [data])

    return alert ? (
        <div className="alert-message pop" style={{ backgroundColor: types[alert.type] }}>{ alert.message }</div>
    ) : null

}

export default Alert
import React, { useContext, useEffect } from 'react'
import '../App.css'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// Context
import DataContext from '../scripts/DataContext'
import BadgeContext from '../scripts/BadgeContext'
import { UserContext } from '../scripts/UserContext'
import { AppContext } from '../scripts/AppContext'
import { loginMenu, mainMenu } from '../scripts/Menu'
import { authAxios } from '../scripts/Axios'

// Components
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import Loader from '../components/Loader'

function Navigation() {

  const { user, setUser, setToken } = useContext(UserContext)
  const { loading, setLoading, dataLoaded } = useContext(AppContext)

  // Initial User Login Check
  useEffect(() => {
    let localToken = localStorage.getItem('token') ? localStorage.getItem('token') : null
    if(localToken) loginWithToken(localToken)
  }, [])

  const loginWithToken = (localToken) => {
    setLoading(true)

    authAxios.get('login/verify')
    .then(response => {

        setLoading(false)

        if(response.data.type !== 'error'){
            setUser(response.data)
            setToken(localToken)
            localStorage.setItem('user', JSON.stringify(response.data))
        }

    })
    .catch(error => {
        console.log(error.response.data)
    })

}

  return (
    <Router>

      { user === null &&
        <Switch>
          {
          loginMenu.map((el, index) => {
            return (
              <Route key={index} exact={el.exact} path={el.path}>
                {el.screen !== null ? <el.screen /> : 'No screen component found.'}
              </Route>
            )
          })
          }              
        </Switch>
      }

      { user !== null &&
      <DataContext>
      <BadgeContext>

        { dataLoaded &&
          <div id="app">

            <Sidebar menu={mainMenu} />

            <div id="content">

              { loading && <Loader /> }

              <Switch>
              {
                mainMenu.map((el, index) => {
                  return el.allowedRoles === null || el.allowedRoles.includes(user.role) ? (
                    <Route key={index} exact={el.exact} path={el.path}>
                      <Header title={el.title} />
                      {el.screen !== null ? <el.screen params={el.params} /> : 'No screen component found.'}
                    </Route>
                  ) : (
                    <Route key={index} exact={el.exact} path={el.path}>
                      <Header title={'Not authorized'} />
                    </Route>
                  )
                })
              }              
              </Switch>

            </div>

          </div>
        }
        
      </BadgeContext>
      </DataContext>
      }

    </Router>
  )

}

export default Navigation

import React, { useContext, useEffect, useState } from 'react'
import '../App.css'
import { Link } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'
import Dropzone from 'react-dropzone'
import { Scrollbars } from 'react-custom-scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faComment, faCheck } from '@fortawesome/free-solid-svg-icons'
import { authAxios } from '../scripts/Axios'
import moment from 'moment'

import Window from '../components/Window'
import Table from '../components/Table'
import Modal from '../components/Modal'
import Selectbox from '../components/Selectbox'
import Document from '../components/Document'
import Alert from '../components/Alert'
import MaxTextarea from '../components/MaxTextarea'

import { AppContext } from '../scripts/AppContext'
import { DataContext } from '../scripts/DataContext'
import { BadgeContext } from '../scripts/BadgeContext'


function CommunicationScreen() {

    const { setLoading } = useContext(AppContext)
    const { users } = useContext(DataContext)
    const { badges } = useContext(BadgeContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [messages, setMessages] = useState([])
    const [chats, setChats] = useState([])

    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [addMessageModal, setAddMessageModal] = useState(false)
    const [addMessageInputs, setAddMessageInputs] = useState(null)

    const [messageEditable, setMessageEditable] = useState(true)

    const [normalMessages, setNormalMessages] = useState([])
    const [showSystemMails, setShowSystemMails] = useState(false)

    const [smsCheck, setSmsCheck] = useState(false)
    const [smsInputs, setSmsInputs] = useState({
        user_id: null,
        message: '',
    })


    useEffect(() => {

        setLoading(true)
        
        authAxios.get('templates')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setTemplates(response.data.templates.filter(t => t.system == 0))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

        authAxios.get('messages')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setMessages(response.data.messages)
            }

        })
        .catch(error => {
            console.log(error)
        })

        authAxios.get('new-chats')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setChats(response.data.chats)
            }

        })
        .catch(error => {
            console.log(error)
        })

    }, [])




    useEffect(() => {
        setNormalMessages(messages.filter(msg => parseInt(msg.von) !== 0))
    }, [messages])
    



    useEffect(() => {

        if(addMessageInputs && selectedTemplate){

            let template = templates.find(t => t.id === selectedTemplate.template_id)
            if(template){

                setLoading(true)
                setMessageEditable(false)

                authAxios.get('template-attachments/' + template.id)
                .then(response => {
        
                    if(response.data.type === 'error'){
                        alert(response.data.message)
                    }else{
                        
                        setLoading(false)

                        setAddMessageInputs({
                            ...addMessageInputs,
                            absender: template.absender,
                            smtp: template.smtp,
                            betreff: template.betreff,
                            nachricht: template.template,
                            anhang: response.data.uploads,
                        })

                        setMessageEditable(true)

                    }
        
                })
                .catch(error => {
                    console.log(error.response.data)
                })

            }

        }

    }, [selectedTemplate])

    
    function handleAddMessage(){
        setAddMessageModal(true)
        setAddMessageInputs({
            empfaenger: null,
            empfaenger_id: 0,
            absender: '',
            betreff: '',
            nachricht: null,
            anhang: [],
        })
        setSelectedTemplate({
            template_id: null,
        })
    }
    

    function saveAddMessage(){

        setLoading(true)

        authAxios.post('message', {
            user_id: 0,
            template: selectedTemplate.template_id,
            ...addMessageInputs,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setMessages([
                    {
                        ...response.data.message,
                        created_at: moment(),
                    },
                    ...messages,
                ])
                setAddMessageModal(false)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    
    function handleUpload(files){

        setLoading(true)

        let data = new FormData()
        files.map((file, index) => {
            data.append('files[' + index + ']', file)
        })
        
        authAxios.post('admin-upload', data)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAddMessageInputs({
                    ...addMessageInputs,
                    anhang: [
                        ...addMessageInputs.anhang,
                        ...response.data.uploads,
                    ],
                })
            }

        })
        .catch(error => {
            console.log(error)
        })

    }


    function sendSMS(){

        if(!smsInputs.user_id || !smsInputs.message){
            setAlertMessage({ type: 'error', message: 'Bitte alle Felder ausfüllen.' })
            setSmsCheck(false)
            return
        }

        setLoading(true)

        authAxios.post('send-sms', smsInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAlertMessage({ type: 'success', message: 'SMS gesendet!' })
                setSmsInputs({user_id: null, message: ''})
                setSmsCheck(false)
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <div className="window-row">
                    <div className="col" style={{ flex: '.75 1' }}>

                        <Window name="messages" title="Nachrichten" actions={
                            <div style={{marginLeft:'auto'}}>
                                <button onClick={e => handleAddMessage()}><FontAwesomeIcon icon={faPlus} /> Nachricht erstellen</button>
                            </div>
                        }>

                            <Table name="tableCommunication" data={showSystemMails ? messages : normalMessages} headers={[
                                {
                                    field: 'created_at',
                                    label: 'Datum',
                                    type: 'datetime',
                                    size: 'm',
                                },
                                {
                                    field: 'betreff',
                                    label: 'Betreff',
                                    type: 'text',
                                    size: 'xl',
                                },
                                {
                                    field: 'user_id',
                                    label: 'Empfänger',
                                    type: 'recipient',
                                    size: 'l',
                                },
                                {
                                    field: 'von',
                                    label: 'Absender',
                                    type: 'admin',
                                    size: 'm',
                                },
                                {
                                    field: 'anhang',
                                    label: 'Anhang',
                                    type: 'attachments',
                                    size: 'xs',
                                },
                            ]} perPage={25} footerContent={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label className="checkContainer">
                                        <input type="checkbox" checked={showSystemMails} onChange={() => setShowSystemMails(!showSystemMails)} /> <span>System-Mails anzeigen</span>
                                    </label>
                                    <Link to={'/communication/logs'} style={{ marginLeft: 30, fontSize: 14 }}>Logs ansehen</Link>
                                </div>
                            }  />

                        </Window>

                    </div>
                    <div className="col" style={{ flexGrow: 0, flex: '.25 1' }}>

                        <Window name="newChats" title="Neue Chat-Nachrichten" actions={badges.communication !== 0 && <span className="count">{badges.communication}</span>}>

                            { chats.length < 1 && <div className="nohits">Keine neuen Chat-Nachrichten.</div> }

                            {chats.map((chat, index) => {
                                let user = users.find(user => user.id === chat.user_id)
                                return user ? (
                                    <div key={index} className="newChat">
                                        <div>
                                            <div className="chatCount">{chat.total} neue {chat.total > 1 ? 'Nachrichten' : 'Nachricht'}</div>
                                            <div className="chatBy">von {user.fullname}</div>
                                        </div>
                                        <div className="table-actions">
                                            <Link to={'/user/' + user.id + '/communication'}>
                                                <button><FontAwesomeIcon icon={faComment} /> Beantworten</button>
                                            </Link>
                                        </div>
                                    </div>
                                ) : null
                            })}

                        </Window>

                        <Window name="smsPanel" title="SMS versenden">
                            
                            <div className="containerSmall">

                                <div className="row">
                                    <div className="col">
                                        <label>Empfänger<br />
                                        <Selectbox options={users.filter(user => user.mobile != null && user.mobile != '' && user.konto != 0 && user.konto != 5 ).map(user => {
                                            return {
                                                label: user.fullname,
                                                value: user.id,
                                            }
                                        })} name="user_id" state={smsInputs} setState={setSmsInputs} />
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <MaxTextarea max={320} label="Nachricht" name="message" state={smsInputs} setState={setSmsInputs} />
                                    </div>
                                </div>

                                { !smsCheck && <button onClick={() => setSmsCheck(true)}>Senden</button> }
                                
                                { smsCheck &&
                                <div>
                                    <button className="saveButton" onClick={sendSMS}>Jetzt senden</button>
                                    <button className="cancelButton" onClick={() => setSmsCheck(false)}>Abbrechen</button>
                                </div>
                                }

                            </div>

                        </Window>

                    </div>

                </div>

                
            { addMessageModal &&
                <Modal title="Nachricht erstellen" toggle={setAddMessageModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddMessageModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddMessage()}><FontAwesomeIcon icon={faCheck} /> Jetzt senden</button>
                        </div>
                    </div>
                }>
                                   
                    <div className="row">
                        <div className="col">
                            <label>Template<br />
                            <Selectbox options={templates.map(template => {
                                return { label: template.name, value: template.id }
                            })} name="template_id" state={selectedTemplate} setState={setSelectedTemplate} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{margin: 0}}>
                                <div className="col">
                                    <label>Absender<br />
                                        <input type="text" value={addMessageInputs.absender} onChange={e => setAddMessageInputs({...addMessageInputs, absender: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>&nbsp;<br />
                                        <Selectbox options={[{ label: '@senihelp24.de', value: 'smtp' }, { label: '@senihelp24.pl', value: 'smtp_pl' }]} name="smtp" state={addMessageInputs} setState={setAddMessageInputs} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <label>Empfänger<br />
                                <Selectbox options={[
                                    { label: 'Alle Kunden', value: 'pbs' },
                                    { label: 'Kunden (verfügbar)', value: 'pbs_searching' },
                                    { label: 'Kunden (in Betreuung)', value: 'pbs_caring' },
                                    { label: 'Kunden (pausierend)', value: 'pbs_pausing' },
                                    { label: 'Alle Betreuungskräfte', value: 'bks' },
                                    { label: 'Betreuungskräfte (verfügbar)', value: 'bks_searching' },
                                    { label: 'Betreuungskräfte (in Betreuung)', value: 'bks_caring' },
                                    { label: 'Betreuungskräfte (pausierend)', value: 'bks_pausing' },
                                ]} name="empfaenger" state={addMessageInputs} setState={setAddMessageInputs} />
                            </label>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col">
                            <label>Betreff<br />
                            <input type="text" value={addMessageInputs.betreff} onChange={e => setAddMessageInputs({...addMessageInputs, betreff: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div>
                        <label>Nachricht</label>
                        <CKEditor
                            editor={ BalloonEditor }
                            data={ addMessageInputs.nachricht }
                            onChange={(e, editor) => {
                                if(messageEditable){
                                    setAddMessageInputs({...addMessageInputs, nachricht: editor.getData()})
                                }
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Anhänge</label>                           
                            <div className="document-list" style={{marginTop:10}}>
                            { addMessageInputs.anhang &&
                                addMessageInputs.anhang.map((document, index) => <Document key={index} index={index} file={document} attachment={true} />)
                            }
                            </div>
                            <div className="uploader">                      
                                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Hier klicken oder Dateien hineinziehen</p>
                                            <p>
                                                <small>Erlaubte Formate: jpg, png, pdf, doc, docx, xls, xlsx</small>
                                            </p>
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                        </div>
                    </div> 

                </Modal>
            }

            </div>
        </Scrollbars>
    )

}

export default CommunicationScreen
import React, { useEffect, useState, useContext } from 'react'
import '../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faEye } from '@fortawesome/free-solid-svg-icons'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'
import Dropzone from 'react-dropzone'

import { authAxios } from '../../scripts/Axios'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import { ContactType } from '../../scripts/Functions'

import Window from '../../components/Window'
import Chat from '../../components/Chat'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import Selectbox from '../../components/Selectbox'
import Document from '../../components/Document'
import Alert from '../../components/Alert'
import moment from 'moment'

function CommunicationScreen({user, setUser, userBadges, updateUserBadges}) {

    const { setLoading } = useContext(AppContext)
    const { checkScope } = useContext(UserContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [addMessageModal, setAddMessageModal] = useState(false)
    const [addMessageInputs, setAddMessageInputs] = useState(null)

    const [messageEditable, setMessageEditable] = useState(true)

    const [currentMessage, setCurrentMessage] = useState(null)
    const [viewMessageModal, setViewMessageModal] = useState(false)


    useEffect(() => {

        authAxios.get('templates')
        .then(response => {

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setTemplates(response.data.templates.filter(t => t.system == 0))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }, [])


    useEffect(() => {

        if(addMessageInputs && selectedTemplate){

            let template = templates.find(t => t.id === selectedTemplate.template_id)
            if(template){

                setLoading(true)
                setMessageEditable(false)


                authAxios.get('template-attachments/' + template.id)
                .then(response => {
        
                    if(response.data.type === 'error'){
                        setAlertMessage({ type: response.data.type, message: response.data.message })
                    }else{
                        
                        setLoading(false)

                        setAddMessageInputs({
                            ...addMessageInputs,
                            empfaenger_id: getInitialRecipientID(template),
                            absender: template.absender,
                            smtp: template.smtp,
                            betreff: template.betreff,
                            nachricht: template.template,
                            anhang: response.data.uploads,
                        })

                        setMessageEditable(true)

                    }
        
                })
                .catch(error => {
                    console.log(error)
                })

            }

        }

    }, [selectedTemplate])


    function getInitialRecipientID(template = null){        
        let empfaenger_id = null
        if(user.user_role === 1){
            empfaenger_id = user.id
        }
        else if(template){
            let contact = user.contacts.find(contact => contact.kategorie && contact.kategorie.includes(template.empfaenger))
            empfaenger_id = contact ? contact.id : null
        }
        return empfaenger_id
    }
    
    
    function handleAddMessage(){
        setAddMessageModal(true)
        setAddMessageInputs({
            smtp: 'smtp',
            empfaenger: user.user_role === 1 ? 'user' : 'contact',
            empfaenger_id: getInitialRecipientID(),
            absender: '',
            betreff: '',
            nachricht: null,
            anhang: [],
        })
        setSelectedTemplate({
            template_id: null,
        })
    }

    function saveAddMessage(){

        setLoading(true)

        authAxios.post('message', {
            user_id: user.id,
            template: selectedTemplate.template_id,
            ...addMessageInputs,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAddMessageModal(false)
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }
    
    function handleUpload(files){

        setLoading(true)

        let data = new FormData()
        files.map((file, index) => {
            data.append('files[' + index + ']', file)
        })
        
        authAxios.post('admin-upload', data)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAddMessageInputs({
                    ...addMessageInputs,
                    anhang: [
                        ...addMessageInputs.anhang,
                        ...response.data.uploads,
                    ],
                })
            }

        })
        .catch(error => {
            console.log(error)
        })

    }


    function viewMessage(id){
        let msg = user.messages.find(m => m.id === id)
        setCurrentMessage(msg)
        setViewMessageModal(true)
    }

    return (
        <div className="page-content">

            <Alert data={alertMessage} />

            <div className="window-row">

                <div className="col">

                    <Window name="userMessages" title="Nachrichten" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ? (
                                <button onClick={e => handleAddMessage()}><FontAwesomeIcon icon={faPlus} /> Nachricht erstellen</button>
                            ) : null }
                        </div>
                    }>

                        <Table name="tableUserCommunication" data={user.messages} headers={[
                            {
                                field: 'created_at',
                                label: 'Datum',
                                type: 'datetime',
                                size: 'm',
                            },
                            {
                                field: 'betreff',
                                label: 'Betreff',
                                type: 'text',
                                size: 'xl',
                            },
                            {
                                field: 'empfaenger_id',
                                label: 'Empfänger',
                                type: 'contactRecipient',
                                size: 'l',
                            },
                            {
                                field: 'von',
                                label: 'Absender',
                                type: 'admin',
                                size: 'm',
                            },
                            {
                                field: 'anhang',
                                label: 'Anhang',
                                type: 'attachments',
                                size: 'xs',
                            },
                            {
                                field: 'confirmation',
                                label: 'Bestätigung',
                                type: 'confirmstatus',
                                size: 'm',
                            },
                        ]} onEdit={viewMessage} editIcon={faEye} editText="Ansehen" currentUser={user} />

                    </Window>

                </div>

            </div>

            <div className="window-row">

                <div className="col" style={{ flex: '.5 1' }}>

                    <Window name="userChat" title="Chat" actions={userBadges.user_chat !== 0 && <span className="count">{userBadges.user_chat}</span>}>
                        { user && <Chat user={user} updateUserBadges={updateUserBadges} /> }
                    </Window>

                </div>
                
            </div>


            { viewMessageModal &&
                <Modal title={currentMessage && currentMessage.betreff} toggle={setViewMessageModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setViewMessageModal(false)}>Schliessen</button>
                        </div>
                    </div>
                }>
                                
                    <div dangerouslySetInnerHTML={{__html: currentMessage && currentMessage.nachricht}}></div>

                </Modal>
            }

            { addMessageModal &&
                <Modal title="Nachricht erstellen" toggle={setAddMessageModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddMessageModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddMessage()}><FontAwesomeIcon icon={faCheck} /> Jetzt senden</button>
                        </div>
                    </div>
                }>
                                   
                    <div className="row">
                        <div className="col">
                            <label>Template<br />
                            <Selectbox options={templates.map(template => {
                                return { label: template.name, value: template.id }
                            })} name="template_id" state={selectedTemplate} setState={setSelectedTemplate} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{margin: 0}}>
                                <div className="col">
                                    <label>Absender<br />
                                        <input type="text" value={addMessageInputs.absender} onChange={e => setAddMessageInputs({...addMessageInputs, absender: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>&nbsp;<br />
                                        <Selectbox options={[{ label: '@senihelp24.de', value: 'smtp' }, { label: '@senihelp24.pl', value: 'smtp_pl' }]} name="smtp" state={addMessageInputs} setState={setAddMessageInputs} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <label>Empfänger<br />
                            { user.user_role === 1 ? (
                                <Selectbox options={user.profiles.map(profile => {
                                    return { label: profile.fullname, value: user.id }
                                })} name="empfaenger_id" state={addMessageInputs} setState={setAddMessageInputs} />
                            ) : (
                                <Selectbox options={user.contacts.map(contact => {
                                    return { label: contact.fullname + ' (' + ContactType(contact.kategorie) + ')', value: contact.id }
                                })} name="empfaenger_id" state={addMessageInputs} setState={setAddMessageInputs} />
                            )}
                            </label>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col">
                            <label>Betreff<br />
                            <input type="text" value={addMessageInputs.betreff} onChange={e => setAddMessageInputs({...addMessageInputs, betreff: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div>
                        <label>Nachricht</label>
                        <CKEditor
                            editor={ BalloonEditor }
                            data={ addMessageInputs.nachricht }
                            onChange={(e, editor) => {
                                if(messageEditable){
                                    setAddMessageInputs({...addMessageInputs, nachricht: editor.getData()})
                                }
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Anhänge</label>                           
                            <div className="document-list" style={{marginTop:10}}>
                            { addMessageInputs.anhang &&
                                addMessageInputs.anhang.map((document, index) => <Document key={index} index={index} file={document} attachment={true} />)
                            }
                            </div>
                            <div className="uploader">                      
                                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Hier klicken oder Dateien hineinziehen</p>
                                            <p>
                                                <small>Erlaubte Formate: jpg, png, pdf, doc, docx, xls, xlsx</small>
                                            </p>
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                        </div>
                    </div> 

                </Modal>
            }

        </div>
    )

}

export default CommunicationScreen
import React, { useEffect, useState } from 'react'
import '../App.css'
import { authAxios } from '../scripts/Axios'

function Frame({file}) {

    const [url, setUrl] = useState('')

    useEffect(() => {

        let loadFile = async () => {
            let doc = await authAxios.get(file.uri, {responseType: 'blob', timeout: 60000})
            if(doc.status === 200){
                let u = URL.createObjectURL(doc.data)
                setUrl(u)
            }
        }
        loadFile()

    }, [])

    return url ? (        
        <iframe src={url}>Ihr Browser unterstützt keine iFrames.</iframe>
    ) : <svg className="spinner" viewBox="0 0 50 50"><circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle></svg>

}

export default Frame
import React, { useEffect, useState, useContext } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useParams, useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { authAxios } from '../scripts/Axios'
import { AppContext } from '../scripts/AppContext'

import Window from '../components/Window'
import Modal from '../components/Modal'

function TranslateScreen() {

    const { id } = useParams()
    const history = useHistory()

    const { setLoading } = useContext(AppContext)
    const [orgFields, setOrgFields] = useState([])
    const [transFields, setTransFields] = useState([])
    const [submitModal, setSubmitModal] = useState(false)
    const [notifyUsers, setNotifyUsers] = useState(true)

    useEffect(() => {

        setLoading(true)

        authAxios.get('translation-fields/' + id)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setOrgFields(response.data.orgFields)
                setTransFields(response.data.transFields)
            }

        })
        .catch(error => {
            console.log(error.response)
        })

    }, [])


    function updateTranslationField(index, field, value){

        let items = [...transFields]
        let item = {...items[index]}
        item[field] = value
        items[index] = item
        console.log(items)
        setTransFields(items)

    }

    function handleSave(){

        setLoading(true)

        authAxios.post('translation-fields/' + id, {
            translations: transFields,
        })
        .then(response => {
            setLoading(false)
        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function submitTranslation(){
        
        setLoading(true)

        authAxios.post('submit-translation/' + id, {
            translations: transFields,
            notify: notifyUsers,
        })
        .then(response => {

            setLoading(false)
            setSubmitModal(false)            
            history.push('/translation')

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Window name="profileTranslation" title="Übersetzung" actions={
                    <div style={{marginLeft:'auto'}}>
                        <button className="saveButton" onClick={e => handleSave()}>Speichern</button>
                        <button className="blueButton" onClick={e => setSubmitModal(true)}>Freigeben</button>
                    </div>
                }>
                    <div className="container">

                        { orgFields.map((profile, index) => {
                            return (

                            <div key={index}>

                                <div className="subtitle">Profil {index+1}</div>

                                { Object.keys(profile).map((field, fieldIndex) => {
                                    return profile[field] && field !== 'id' ? (

                                        <div key={fieldIndex} className="row">

                                            <div className="col">
                                                <label style={{textTransform:'capitalize'}}>{field}</label>
                                                <div className="translation-text" dangerouslySetInnerHTML={{ __html: profile[field].replace(/\n/g, '<br />') }}></div>
                                            </div>

                                            <div className="col">
                                                <label style={{textTransform:'capitalize'}}>{field}</label>
                                                <textarea value={transFields[index] ? transFields[index][field] : profile[field]} style={{height: 'calc(100% - 40px)', lineHeight: '21px'}} onChange={e => updateTranslationField(index, field, e.target.value)}></textarea>
                                            </div>

                                        </div>

                                    ) : null
                                }) }

                            </div>

                            )
                        }) }
                    
                    </div>
                </Window>

          
                { submitModal &&
                    <Modal title="Sicher?" toggle={setSubmitModal} actions={
                        <div className="modal-actions">
                            <label className="checkContainer">
                                <input type="checkbox" checked={notifyUsers} onChange={() => setNotifyUsers(!notifyUsers)} /> <span>Benutzer benachrichtigen</span>
                            </label>
                            <div style={{marginLeft:'auto'}}>
                                <button className="cancelButton" onClick={e => setSubmitModal(false)}>Abbrechen</button>
                                <button className="blueButton" onClick={e => submitTranslation()}><FontAwesomeIcon icon={faCheck} /> Freigeben</button>
                            </div>
                        </div>
                    }>
                        Möchten Sie diese Übersetzung wirklich abschließen?<br />
                        Der Benutzer wird dann über die Freigabe informiert und hat Zugriff auf die App.
                    </Modal>
                }

            </div>
        </Scrollbars>
    )

}

export default TranslateScreen
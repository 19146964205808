import React from 'react'
import '../App.css'

import { ProfileField } from '../scripts/Functions'

function CareLevel({value}) {

    if(value <= 0) return null

    const pflegegrad = value - 4 // Achtung bei DB-Änderungen

    const states = [
        '#24ca40',
        '#ffb204',
        '#ff6f2b',
        '#fb381d',
        '#aa198d',
    ]

    return (
        <div className="care-level" style={{ color: states[pflegegrad] }}>
            {pflegegrad}
        </div>
    )

}

export default CareLevel
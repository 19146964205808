import React, { useContext, useEffect, useState } from 'react'
import '../App.css'
import ProfileMenu from './ProfileMenu'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../scripts/AppContext'

function Header({title}) {
    
    const location = useLocation()
    const { pageSubtitle, setPageSubtitle } = useContext(AppContext)

    useEffect(() => {
        if(!location.pathname.startsWith('/user')){
            setPageSubtitle('')
        }
    }, [location.pathname])

    return (
        <div id="header">
            <div id="pagetitle">{ title }</div>
            <div id="pageSubtitle">{ pageSubtitle }</div>
            <ProfileMenu />
        </div>
    )

}

export default Header
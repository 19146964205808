import React, { useState, useEffect } from 'react'
import '../App.css'
import Select from 'react-select'

function Selectbox({options, name, multiple, state, setState, isFilter = false, placeholder, bgColor = '#f4f7f9'}) {

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        initialChange()
    }, [state])


    function initialChange(){
        if(name && state && (state[name] || state[name] === 0)){
            if(multiple){
                let val = options.filter(opt => {
                    if(Array.isArray(state[name])){
                        return state[name].includes(opt.value) // das ist das richtige
                    }else{
                        return state[name] === opt.value // das kann weg (wenn alle einträge in der db ein array ist - ist neu)
                    }
                })
                setSelectedValue(val)
            }else{
                let val = options.length ? options.find(opt => opt.value === state[name]) : null
                setSelectedValue(val)
            }
        }else if(state !== null){
            let val = options.length ? options.find(opt => opt.value === state) : null
            setSelectedValue(val)
        }else{
            setSelectedValue(null)
        }
    }


    function handleChange(opt){
        
        setSelectedValue(opt)

        if(name){
            if(multiple && opt){
                let values = opt.map(o => o.value)
                isFilter ? setState(name, values) : setState({...state, [name]: values})
            }else if(opt){
                isFilter ? setState(name, opt.value) : setState({...state, [name]: opt.value})
            }else{
                isFilter ? setState(name, null) : setState({...state, [name]: null})
            }
        }else{
            setState(opt.value)
        }

    }

    const customStyles = {
        control: provided => ({
            ...provided,
            backgroundColor: bgColor,
            border: '1px solid #d6dfe5',
        }),
        valueContainer: provided => ({
            ...provided,            
            paddingTop: 0,
            paddingBottom: 0,
        }),
        input: provided => ({
            ...provided,
            padding: 0,
            margin: 0,
            height: 38,
        }),
        singleValue: provided => ({
            ...provided,            
            color: '#29363E',
            fontSize: 15,
            fontWeight: 400,
            margin: 0,
        }),
        multiValue: provided => ({
            ...provided,
            backgroundColor: isFilter ? '#d6e1e9' : '#ffffff',
        }),
        multiValueLabel: provided => ({
            ...provided,
            color: '#29363E',
            fontSize: 14,
            fontWeight: 500,
            padding: 4,
        }),
        indicatorSeparator: provided => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: provided => ({
            ...provided,
            color: '#7a8389', 
        }),
    }

    return (
        <div className="selectbox">
            <Select options={options ? options : []} value={selectedValue} isMulti={multiple ? multiple : false} onChange={opt => handleChange(opt)} styles={customStyles} placeholder={placeholder ? placeholder : 'Auswählen ...'} />
        </div>
    )

}

export default Selectbox
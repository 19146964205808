import React from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Modal({children, toggle, title, actions, classes = []}) {

    return (
        <div className="modal-wrapper">
            <div className={['modal', ...classes].join(' ')}>
                <div className="modal-head">
                    <div className="modal-title">{title}</div>
                    <button className="cancelButton modal-close" onClick={() => toggle(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="modal-content">
                    {children}
                </div>                  
                {actions}
            </div>
        </div>
    )

}

export default Modal
import React, { useContext, useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../scripts/AppContext'
import { authAxios } from '../scripts/Axios'
import Dropzone from 'react-dropzone'

import Selectbox from './Selectbox'
import Media from './Media'
import Modal from './Modal'

function Uploader({user, setUser}) {

    const { setLoading } = useContext(AppContext)

    const [category, setCategory] = useState(user.user_role === 1 ? 'person' : null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteID, setDeleteID] = useState(null)

    function handleUpload(files){

        if(!category){
            alert('Bitte wählen Sie zuerst eine Kategorie aus.')
            return
        }

        setLoading(true)

        let data = new FormData()
        data.append('user_id', user.id)
        data.append('kategorie', category)
        files.map((file, index) => {
            data.append('files[' + index + ']', file)
        })
        
        authAxios.post('upload', data)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUser({
                    ...user,
                    media: [
                        ...user.media,
                        ...response.data.uploads
                    ]
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function checkCategory(e){
        if(!category){
            e.stopPropagation()
            alert('Bitte wählen Sie zuerst eine Kategorie aus.')
            return
        }
    }

    function handleDelete(id){        
        setDeleteID(id)
        setDeleteModal(true)
    }

    function deleteFile(id){

        setDeleteModal(false)
        
        authAxios.delete('upload/' + id)
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUser({
                    ...user,
                    media: user.media.filter(media => media.id !== id)
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <div>
        
            <div className="uploader">

                { user.user_role === 2 &&
                <Selectbox placeholder="Kategorie wählen ..." options={[
                    { label: 'Bilder der Person', value: 'person' },
                    { label: 'Bilder der Wohnung', value: 'wohnung' },
                    { label: 'Videos der Wohnung', value: 'video' },
                ]} state={category} setState={setCategory} />
                }

                <br />

                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps({
                            onClick: e => checkCategory(e)
                        })}>
                            <input {...getInputProps()} />
                            <p>Hier klicken oder Dateien hineinziehen um Medien hochzuladen</p>
                            <p>
                                <small>Erlaubte Bild-Formate: jpg, jpeg, png (max. 5 MB)</small><br />
                                <small>Erlaubte Video-Formate: mp4, mov, webm (max. 30 MB und 3 Min.)</small>
                            </p>
                        </div>
                    </section>
                )}
                </Dropzone>

                <div className="subtitle">Bilder der Person</div>
                <div className="media-grid">
                    {
                    user.media.map((media, index) => {
                        if(media.kategorie == 'person'){
                            return (
                                <Media key={index} editable={true} file={media} handleDelete={handleDelete} />
                            )
                        }
                    })
                    }
                </div>

                { user.user_role === 2 &&
                <div>
                    <br />

                    <div className="subtitle">Bilder der Wohnung</div>
                    <div className="media-grid">
                        {
                        user.media.map((media, index) => {
                            if(media.kategorie == 'wohnung'){
                                return (
                                    <Media key={index} editable={true} file={media} handleDelete={handleDelete} />
                                )
                            }
                        })
                        }
                    </div>

                    <br />

                    <div className="subtitle">Videos der Wohnung</div>
                    <div className="media-grid">
                        {
                        user.media.map((media, index) => {
                            if(media.kategorie == 'video'){
                                return (
                                    <Media key={index} editable={true} file={media} handleDelete={handleDelete} />
                                )
                            }
                        })
                        }
                    </div>
                </div>
                }

            </div>
          
            { deleteModal &&
                <Modal title="Sicher?" toggle={setDeleteModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setDeleteModal(false)}>Abbrechen</button>
                            <button className="deleteButton" onClick={e => deleteFile(deleteID)}><FontAwesomeIcon icon={faMinusCircle} /> Löschen</button>
                        </div>
                    </div>
                }>
                    Möchten Sie diese Datei wirklich endgültig löschen?
                </Modal>
            }

        </div>
    )

}

export default Uploader
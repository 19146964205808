import React, { useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import Media from '../components/Media'
import Video from '../components/Video'
import Frame from '../components/Frame'

function DocumentLightbox({file, onClose}) {

    return ( 
        <div className="lightbox">

            <div className="lightbox-bg" onClick={onClose}></div>
            <div className="lightbox-close" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
            </div>

            { ['image/jpeg', 'image/png'].includes(file.typ) &&
                <Media file={file} flex={'0 0 50%'} square={false} type="document" className="noselect" />
            }

            { ['video/mpeg', 'video/mp4', 'video/webm'].includes(file.typ) &&
                <Video file={file} className="noselect" />
            }

            { ['application/pdf'].includes(file.typ) &&
                <Frame file={file} />
            }

            { !['image/jpeg', 'image/png', 'video/mpeg', 'video/mp4', 'video/webm', 'application/pdf'].includes(file.typ) &&
                <div className="notypes">Dieser Dateityp kann nicht agezeigt werden.</div>
            }

        </div>
    )

}

export default DocumentLightbox
import React, { useContext, useState } from 'react'
import '../App.css'
import { NavLink, Switch, Route } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserContext } from '../scripts/UserContext'
import { adminsMenu } from '../scripts/Menu'


function AdminsScreen() {

    const basePath = '/admins'
    const { user: admin } = useContext(UserContext)

    const [settings, setSettings] = useState(null)

    return (
        <div id="admins">

            <div className="profile-tabs">
                {
                adminsMenu.map((el, index) => {
                    if(el.inMenu && el.allowedRoles.includes(admin.role)){
                        return (   
                            <NavLink key={index} className="tab" exact={el.exact} to={basePath + el.path} activeStyle={{color: '#fff', backgroundColor: '#008cdb'}}>
                                <FontAwesomeIcon icon={el.icon} />
                                <span>{el.title}</span>
                            </NavLink>
                        )
                    }
                })
                }
            </div>

            <Switch>
            {
            adminsMenu.map((el, index) => {
                return el.allowedRoles.includes(admin.role) ? (                    
                    <Route key={index} exact={el.exact} path={basePath + el.path}>
                        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page offset dark-scrollbar">
                            {el.screen !== null ? <el.screen settings={settings} setSettings={setSettings} /> : 'No screen component found.'}
                        </Scrollbars>
                    </Route>
                ) : (
                    <Route key={index} exact={el.exact} path={basePath + el.path}>
                        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page offset dark-scrollbar"></Scrollbars>
                    </Route>
                )
            })
            }              
            </Switch>

        </div>
    )

}

export default AdminsScreen
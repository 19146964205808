import React, { useState, useEffect } from 'react'
import '../App.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

function RatingSelect({ name, viewOnly = false, state, setState }) {

    const yellow = '#FFC700'
    const grey = '#D6E1E9'

    const [value, setValue] = useState(0)

    useEffect(() => {
        if(state[name]){
            setValue(state[name])
        }
    }, [state])

    function selectValue(val){
        setValue(val)
        setState({...state, [name]: val})
    }

    return (
        <div className="ratingSelect">
            <div className="star" onClick={() => !viewOnly ? selectValue(1) : null}>
                <FontAwesomeIcon icon={faStar} color={value >= 1 ? yellow : grey} />
            </div>
            <div className="star" onClick={() => !viewOnly ? selectValue(2) : null}>
                <FontAwesomeIcon icon={faStar} color={value >= 2 ? yellow : grey} />
            </div>
            <div className="star" onClick={() => !viewOnly ? selectValue(3) : null}>
                <FontAwesomeIcon icon={faStar} color={value >= 3 ? yellow : grey} />
            </div>
            <div className="star" onClick={() => !viewOnly ? selectValue(4) : null}>
                <FontAwesomeIcon icon={faStar} color={value >= 4 ? yellow : grey} />
            </div>
            <div className="star" onClick={() => !viewOnly ? selectValue(5) : null}>
                <FontAwesomeIcon icon={faStar} color={value >= 5 ? yellow : grey} />
            </div>
        </div>
    )

}

export default RatingSelect
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import '../App.css'

function CareTimeline({ user, year }) {

    const line = useRef(null)
    const [lineWidth, setLineWidth] = useState(0)
    const [dayPixel, setDayPixel] = useState(0)
    const today = moment()

    useEffect(() => {

        updateLineWidth()
        window.addEventListener('resize', updateLineWidth)

        return () => {
            window.removeEventListener('resize', updateLineWidth)
        }

    }, [])

    useEffect(() => setDayPixel(lineWidth / 365), [lineWidth])

    function updateLineWidth(){
        setLineWidth(line.current.offsetWidth)
    }

    function displayBreak(b, meta = false){

        let offset
        let width
        let startDatum = moment(b.von)
        let endDatum = moment(b.bis)

        if(startDatum.isSame(year+'-01-01', 'year') && endDatum){
            offset = Math.floor(startDatum.dayOfYear() * dayPixel)
            offset = offset < 0 ? 0 : offset

            if(endDatum.year() > startDatum.year()){
                width = endDatum ? Math.ceil((365 - startDatum.dayOfYear()) * dayPixel) : 1
            }else{
                width = endDatum ? Math.ceil((endDatum.dayOfYear() - startDatum.dayOfYear()) * dayPixel) : 1
            }
            
        }
        else if(endDatum.year() == year){
            offset = 0
            width = endDatum ? Math.ceil(endDatum.dayOfYear() * dayPixel) : 1
        }
        else return null

        let timeState = 'next'
        if(endDatum && endDatum.isBefore(today)) timeState = 'previous'
        else if(endDatum && today.isBetween(startDatum, endDatum)) timeState = 'current'

        if(!meta){
            return <div key={'break'+b.id} className={'timeline-pause ' + timeState} style={{left: offset, width: width}}></div>
        }else{
            return width > 50 ? (
                <div key={'break'+b.id} className={'timeline-care-meta ' + timeState} style={{left: offset, width: width}}>
                    <div className="timeline-meta-date">{startDatum.format('DD.MM.')} - {endDatum.format('DD.MM.')}</div>
                    <div className="timeline-meta-name">Pause</div>
                </div>
            ) : null
        }
    }

    return (
        <div className="timeline">

            <div className="timeline-months">
                <div>Januar</div>
                <div>Februar</div>
                <div>März</div>
                <div>April</div>
                <div>Mai</div>
                <div>Juni</div>
                <div>Juli</div>
                <div>August</div>
                <div>September</div>
                <div>Oktober</div>
                <div>November</div>
                <div>Dezember</div>
            </div>

            <div ref={line} className="timeline-line">
                {user.cares.slice(0).reverse().filter(care => care.status !== 1 && (moment(care.startdatum).isSame(moment(year+'-01-01'), 'year') || moment(care.enddatum).isSame(moment(year+'-01-01'), 'year')) ).map(care => {

                    let offset
                    let width
                    let startDatum = moment(care.startdatum)
                    let endDatum = care.enddatum ? moment(care.enddatum) : null

                    if(startDatum.isSame(year+'-01-01', 'year') && endDatum){
                        offset = Math.floor(startDatum.dayOfYear() * dayPixel)
                        offset = offset < 0 ? 0 : offset

                        if(endDatum.year() > startDatum.year()){
                            width = endDatum ? Math.ceil((365 - startDatum.dayOfYear()) * dayPixel) : 1
                        }else{
                            width = endDatum ? Math.ceil((endDatum.dayOfYear() - startDatum.dayOfYear()) * dayPixel) : 1
                        }
                        
                    }else{
                        offset = 0
                        width = endDatum ? Math.ceil(endDatum.dayOfYear() * dayPixel) : 1
                    }

                    let timeState = 'next'
                    if(endDatum && endDatum.isBefore(today)) timeState = 'previous'
                    else if(endDatum && today.isBetween(startDatum, endDatum)) timeState = 'current'

                    let autoPauseWidth = dayPixel * 21 // 21 days
                    if(offset + width + autoPauseWidth > lineWidth) autoPauseWidth -= (offset + width + autoPauseWidth) - lineWidth
                    if(autoPauseWidth < 0) autoPauseWidth = 0

                    return (
                        <div key={care.id} className={'timeline-care ' + timeState + (care.status === 0 ? ' planned' : '')} style={{left: offset, width: width}}>
                            { (care.autobreak && endDatum && endDatum.year() == year && user.user_role === 1) ? <div className="timeline-auto-pause" style={{left: width, width: autoPauseWidth}}></div> : null }
                        </div>
                    )

                })}

                { user.breaks && user.breaks.map(b => displayBreak(b) )}
            </div>

            <div ref={line} className="timeline-meta">

                { today.isSame(moment(year+'-01-01'), 'year') && <FontAwesomeIcon icon={faMapMarker} color="#ff5757" className="timeline-today" style={{left: Math.ceil(today.dayOfYear() * dayPixel) - 7}} /> }

                {user.cares.slice(0).reverse().filter(care => care.status !== 1 && (moment(care.startdatum).isSame(moment(year+'-01-01'), 'year') || moment(care.enddatum).isSame(moment(year+'-01-01'), 'year')) ).map(care => {

                    let offset
                    let width
                    let startDatum = moment(care.startdatum)
                    let endDatum = care.enddatum ? moment(care.enddatum) : null
                    
                    if(startDatum.isSame(year+'-01-01', 'year') && endDatum){
                        offset = Math.floor(startDatum.dayOfYear() * dayPixel)
                        offset = offset < 0 ? 0 : offset

                        if(endDatum.year() > startDatum.year()){
                            width = endDatum ? Math.ceil((365 - startDatum.dayOfYear()) * dayPixel) : 1
                        }else{
                            width = endDatum ? Math.ceil((endDatum.dayOfYear() - startDatum.dayOfYear()) * dayPixel) : 1
                        }

                    }else{
                        offset = 0
                        width = endDatum ? Math.ceil(endDatum.dayOfYear() * dayPixel) : 1
                    }

                    let timeState = 'next'
                    if(endDatum && endDatum.isBefore(today)) timeState = 'previous'
                    else if(endDatum && today.isBetween(startDatum, endDatum)) timeState = 'current'

                    return width >= 100 ? (
                        <div key={care.id} className={'timeline-care-meta ' + timeState} style={{left: offset, width: width}}>
                            <div className="timeline-meta-date">{startDatum.format('DD.MM.')} { endDatum && '- ' + endDatum.format('DD.MM.')}</div>
                            <div className="timeline-meta-name">{user.user_role === 1 ? care.pb_name : care.bk_name}</div>
                        </div>
                    ) : null

                })}

                { user.breaks && user.breaks.map(b => displayBreak(b, true) )}
            </div>

        </div>
    )

}

export default CareTimeline
import React from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'

function Badge({value}) {

    if(!value){ value = 0 }

    const states = [
        {
            title: 'Kein Status',
            color: '#29363E',
        },
        {
            title: 'Bronze-Status',
            color: '#d47835',
        },
        {
            title: 'Silber-Status',
            color: '#9c9c9c',
        },
        {
            title: 'Gold-Status',
            color: '#dda430',
        },
    ]

    return (
        <div style={{ color: states[value].color }}>
            { value > 0 && <FontAwesomeIcon icon={faCrown} style={{marginRight:8}} /> }
            { states[value].title }
        </div>
    )

}

export default Badge
import React, { createContext, useEffect, useState } from 'react'

import { authAxios } from '../scripts/Axios'
import { useInterval } from '../scripts/Functions'

export const BadgeContext = createContext(null)

export default ({children}) => {

    const [badges, setBadges] = useState({
        dashboard: 0,
        tasks: 0,
        cares: 0,
        communication: 0,
        translation:  0,
    })

    function updateBadges(){
        
        authAxios.get('badges')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setBadges(response.data.badges)
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    useEffect(() => {
        updateBadges()
    }, [])

    useInterval(() => updateBadges(), 10 * 1000)

    const data = {
        badges, updateBadges
    }

    return (
        <BadgeContext.Provider value={data}>
            {children}
        </BadgeContext.Provider>
    )
    
}





import React, { useContext, useEffect, useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router-dom'
import { authAxios } from '../scripts/Axios'
import { updatePref, getPref } from '../scripts/Functions'

import Window from '../components/Window'
import Table from '../components/Table'
import Selectbox from '../components/Selectbox'

import { AppContext } from '../scripts/AppContext'

function ContactListScreen({ params }) {

    const { setLoading } = useContext(AppContext)
    const history = useHistory()

    const [users, setUsers] = useState([])

    const [filteredUsers, setFilteredUsers] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const filterDefault = {
        search: '',
        geschlecht: null,
        kategorie: null,
    }
    const [filters, setFilters] = useState(filterDefault)


    useEffect(() => {

        setLoading(true)

        authAxios.get('users/contacts')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUsers(response.data.contacts)
                setFilteredUsers(response.data.contacts)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        .then(() => setFilterPref())

    }, [])


    async function setFilterPref(){        
        let filterPref = await getPref('contact_filter')
        if(filterPref !== null){
            filterPref.search = filterPref.search === null ? '' : filterPref.search
            if(filterPref !== null) setFilters(filterPref)
        }
    }
    
    
    function handleEditContact(id){
        history.push('/user/'+id+'/profiledata')
    }
    

    useEffect(() => {

        let filtered = users

        // Text-Search
        filtered = filtered.filter(contact => {
            if(filters.search === ''){ return true }
            let isMatching = false
            let searchTags = filters.search.split(' ')
            searchTags.filter(t => t !== '').map(tag => {

                if(
                    (contact.vorname ? (contact.vorname.toLowerCase().includes(tag.toLowerCase())) : false) ||
                    (contact.nachname ? (contact.nachname.toLowerCase().includes(tag.toLowerCase())) : false) ||
                    (contact.email ? (contact.email.toLowerCase().includes(tag.toLowerCase())) : false) ||
                    contact.user_id === parseInt(tag)
                ){
                    isMatching = true
                }
                
            })
            return isMatching
        })
        // Geschlecht
        filtered = filtered.filter(contact => {
            let isSame = false
            if(!filters.geschlecht){
                isSame = true
            }
            else{
                if(contact.anrede === filters.geschlecht){
                    isSame = true
                }
            }
            return isSame
        })
        // Kategorie
        filtered = filtered.filter(contact => {
            let isSame = false
            if(!filters.kategorie){
                isSame = true
            }
            else{
                if(contact.kategorie){
                    if(contact.kategorie.includes(filters.kategorie)){
                        isSame = true
                    }
                }
            }
            return isSame
        })

        setFilteredUsers(filtered)

    }, [filters])


    function changeFilter(key, value){

        let newFilter = null

        if(
            key === 'geschlecht' ||
            key === 'kategorie'
        ){
            newFilter = {
                ...filters,
                [key]: value !== '' ? value : null,
            }
            setFilters(newFilter)
        }

        updatePref('contact_filter', newFilter)

    }


    function setTextFilter(val){
        setFilters({
            ...filters,
            search: val,
        })
    }

    function resetFilter(){
        setFilters(filterDefault)
        updatePref('contact_filter', filterDefault)
    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Window name="contactUserList" title={filteredUsers.length + ' Ansprechpartner'} actions={
                    <div style={{flexDirection:'row', marginLeft:'auto'}}>
                        <input type="search" className="search-input" placeholder="Suche ..." onChange={e => setTextFilter(e.target.value)} />
                        <button onClick={e => setShowFilter(!showFilter)}><FontAwesomeIcon icon={faFilter} /> {!showFilter ? 'Filter anzeigen' : 'Filter verbergen'}</button>
                    </div>
                }>

                    { showFilter &&
                    <div className="filters">
                        
                        <div className="row" style={{width:'fit-content'}}>
                            <div className="col" style={{marginRight:20, minWidth:200}}>

                                <label>Geschlecht</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Alle', value: null },
                                            { label: 'Männlich', value: 'Herr' },
                                            { label: 'Weiblich', value: 'Frau' },
                                            { label: 'Divers', value: 'Divers' },
                                        ]} name="geschlecht" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                            </div>
                            <div className="col" style={{marginRight:20, minWidth:200}}>

                                <label>Typ</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            { label: 'Gesetzlicher Betreuer', value: 1 },
                                            { label: 'Hauptkontakt', value: 2 },
                                            { label: 'Rechnungsempfänger', value: 3 },
                                        ]} name="kategorie" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />  
                                    </div>
                                </div> 

                            </div>                                

                        </div>

                        <button onClick={e => resetFilter()} className="deleteLink" style={{paddingTop:12, paddingBottom:12, paddingLeft:6, paddingRight:6, height: 'auto'}}><FontAwesomeIcon icon={faTimes} />Filter zuücksetzen</button>

                    </div>
                    }


                    <Table name="tableContacts" data={filteredUsers} perPage={25} headers={[
                        {
                            field: 'anrede',
                            label: 'Anrede',
                            type: 'text',
                            size: 's',
                        },
                        {
                            field: 'vorname',
                            label: 'Vorname',
                            type: 'text',
                            size: 'm',
                        },
                        {
                            field: 'nachname',
                            label: 'Nachname',
                            type: 'text',
                            size: 'm',
                        },
                        {
                            field: 'email',
                            label: 'E-Mail',
                            type: 'text',
                            size: 'm',
                        },
                        {
                            field: 'mobile',
                            label: 'Handynummer',
                            type: 'text',
                            size: 'm',
                        },
                        {
                            field: 'user_id',
                            label: 'Zugehörige Person',
                            type: 'user',
                            size: 'm',
                        },
                        {
                            field: 'kategorie',
                            label: 'Typ',
                            type: 'contactType',
                            size: 'l',
                        },
                    ]} onEdit={handleEditContact} editIdField={'user_id'} />

                </Window>                

            </div>
        </Scrollbars>
    )

}

export default ContactListScreen
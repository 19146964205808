import React, { useContext, useState, useRef } from 'react'
import '../App.css'
import { AppContext } from '../scripts/AppContext'
import { UserContext } from '../scripts/UserContext'
import { authAxios } from '../scripts/Axios'
import { useOutsideClick } from '../scripts/Functions'
import AccountStatus from './AccountStatus'

function AccountStatusSelector({user, setUser}) {

    const { setLoading } = useContext(AppContext)
    const { checkScope } = useContext(UserContext)
    const [optionsVisible, setOptionsVisible] = useState(false)

    const refSelector = useRef(null)
    useOutsideClick(refSelector, () => setOptionsVisible(false))

    const states = [
        'Konto gesperrt', // 0
        'Pofilerstellung', // 1
        'Neu registriert', // 2
        'In Übersetzung', // 3
        'Konto aktiv', // 4
        'Archiviert', // 5
    ]

    function handleChange(e){

        let val = e.target.getAttribute('data-value')
        setLoading(true)
        
        authAxios.put('accountstatus/' + user.id, {
            konto: val,
            notify: true,
        })
        .then(response => {

            setOptionsVisible(false)
            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUser({...user, konto: response.data.konto})
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ?
    (
        <div className="account-status-selector noselect">
            <div className="account-status-selection" onClick={e => setOptionsVisible(!optionsVisible)}>
                <AccountStatus value={user.konto} /> {states[user.konto]}
            </div>
            { optionsVisible &&
            <div ref={refSelector} className="account-status-options">
                {
                states.map((state, index) => {
                    return (
                        <div key={index} className="account-status-option" data-value={index} onClick={e => handleChange(e)}>
                            <AccountStatus value={index} /> {state}
                        </div>
                    )
                })
                }
            </div>
            }
        </div>
    ) : (
        <div className="account-status-selector noselect noarrow">
            <div className="account-status-selection">
                <AccountStatus value={user.konto} /> {states[user.konto]}
            </div>
        </div>
    )

}

export default AccountStatusSelector
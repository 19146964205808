import React from 'react'
import '../App.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'

function Rating({value}) {

    const val = value ? value : 0
    const yellow = '#FFC700'
    const grey = '#D6E1E9'

    return (
        <div className="rating">
            <FontAwesomeIcon icon={val > 0.25 && val <= .75 ? faStarHalfAlt : faStar} color={val > 0.25 ? yellow : grey} />
            <FontAwesomeIcon icon={val > 1.25 && val <= 1.75 ? faStarHalfAlt : faStar} color={val > 1.25 ? yellow : grey} />
            <FontAwesomeIcon icon={val > 2.25 && val <= 2.75 ? faStarHalfAlt : faStar} color={val > 2.25 ? yellow : grey} />
            <FontAwesomeIcon icon={val > 3.25 && val <= 3.75 ? faStarHalfAlt : faStar} color={val > 3.25 ? yellow : grey} />
            <FontAwesomeIcon icon={val > 4.25 && val <= 4.75 ? faStarHalfAlt : faStar} color={val > 4.25 ? yellow : grey} />
        </div>
    )

}

export default Rating
import { faColumns, faTasks, faEnvelope, faUser, faUserTie, faWheelchair, faGlobe, faCog, faCalendarAlt, faMobile, faStar, faEnvelopeOpenText, faFileImport, faImage, faUsers } from '@fortawesome/free-solid-svg-icons'
import { faUser as farUser } from '@fortawesome/free-regular-svg-icons'

// Login Screens
import LoginScreen from '../screens/login/Login'
import ForgotPasswordScreen from '../screens/login/ForgotPassword'
import ResetPasswordScreen from '../screens/login/ResetPassword'

// Main Screens
import DashboardScreen from '../screens/Dashboard'
import TasksScreen from '../screens/Tasks'
import CaresScreen from '../screens/Cares'
import UserListScreen from '../screens/UserList'
import UserScreen from '../screens/User'
import CommunicationScreen from '../screens/Communication'
import CommunicationLogsScreen from '../screens/CommunicationLogs'
import TranslationScreen from '../screens/Translation'
import TranslateScreen from '../screens/Translate'
import AppScreen from '../screens/App'
import AdminsScreen from '../screens/Admins'
import AdminProfileScreen from '../screens/AdminProfile'

// Profile Screens
import ProfileCaresScreen from '../screens/profile/ProfileCares'
import ProfileDataScreen from '../screens/profile/ProfileData'
import MediaDocumentsScreen from '../screens/profile/MediaDocuments'
import ProfileCommunicationScreen from '../screens/profile/Communication'
import TasksNotesScreen from '../screens/profile/TasksNotes'
import RatingsScreen from '../screens/profile/Ratings'
import SettingsScreen from '../screens/Settings'

// Admins Screens
import UsersAdminsScreen from '../screens/admins/UsersAdminsScreen'
import GroupsAdminsScreen from '../screens/admins/GroupsAdminsScreen'

// Settings Screens
import CommunicationSettingsScreen from '../screens/settings/CommunicationSettings'
import ImportSettingsScreen from '../screens/settings/ImportSettings'
import ContactListScreen from '../screens/ContactList'



export const loginMenu = [
  {
    title: 'Login',
    slug: 'dashboard',
    screen: LoginScreen,
    path: '/',
    exact: true,
  },
  {
    title: 'Passwort vergessen',
    slug: 'forgot_password',
    screen: ForgotPasswordScreen,
    path: '/forgot-password',
    exact: true,
  },
  {
    title: 'Passwort zurücksetzen',
    slug: 'reset_password',
    screen: ResetPasswordScreen,
    path: '/reset-password',
    exact: true,
  },
]

export const mainMenu = [
    {
      title: 'Dashboard',
      slug: 'dashboard',
      screen: DashboardScreen,
      icon: faColumns,
      path: '/',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,3,4,5],
    },
    {
      title: 'Aufgaben',
      slug: 'tasks',
      screen: TasksScreen,
      icon: faTasks,
      path: '/tasks',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,3,4,5,6],
    },
    {
      title: 'Betreuungen',
      slug: 'cares',
      screen: CaresScreen,
      icon: faCalendarAlt,
      path: '/cares',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,3,4],
    },
    {
      title: 'Pflegebedürftige',
      slug: 'pbs',
      screen: UserListScreen,
      icon: faWheelchair,
      path: '/users/pb',
      params: { user_role: 2 },
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,3,4,5,6],
    },
    {
      title: 'Betreuungskräfte',
      slug: 'bks',
      screen: UserListScreen,
      icon: faUserTie,
      path: '/users/bk',
      params: { user_role: 1 },
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,3,4,5,6],
    },
    {
      title: 'Ansprechpartner',
      slug: 'contacts',
      screen: ContactListScreen,
      icon: farUser,
      path: '/users/contacts',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,3,4],
    },
    {
      title: 'Profil',
      slug: 'profile',
      screen: UserScreen,
      path: '/user/:id',
      inMenu: false,
      exact: false,
      allowedRoles: [1,2,3,4,5,6],
    },
    {
      title: 'Kommunikation',
      slug: 'communication',
      screen: CommunicationScreen,
      icon: faEnvelope,
      path: '/communication',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2],
    },
    {
      title: 'E-Mail Logs',
      slug: 'communication-logs',
      screen: CommunicationLogsScreen,
      icon: faEnvelope,
      path: '/communication/logs',
      inMenu: false,
      exact: true,
      allowedRoles: [1],
    },
    {
      title: 'Profilübersetzung',
      slug: 'translation',
      screen: TranslationScreen,
      icon: faGlobe,
      path: '/translation',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2,6],
    },
    {
      title: 'App-Informationen',
      slug: 'app',
      screen: AppScreen,
      icon: faMobile,
      path: '/app',
      inMenu: true,
      exact: true,
      allowedRoles: [1,2],
    },
    {
      title: 'Übersetzung',
      slug: 'translate',
      screen: TranslateScreen,
      path: '/translate/:id',
      inMenu: false,
      exact: false,
      allowedRoles: [1,2,5,6],
    },
    {
      title: 'Benutzer',
      slug: 'admins',
      screen: AdminsScreen,
      icon: faUser,
      path: '/admins',
      inMenu: true,
      exact: false,
      allowedRoles: [1],
    },
    {
      title: 'Einstellungen',
      slug: 'settings',
      screen: SettingsScreen,
      icon: faCog,
      path: '/settings',
      inMenu: true,
      exact: false,
      allowedRoles: [1,2],
    },
    {
      title: 'Mein Profil',
      slug: 'profile',
      screen: AdminProfileScreen,
      path: '/profile',
      inMenu: false,
      exact: false,
      allowedRoles: null,
    }
]

export const userMenu = [
    {
      title: 'Betreuungen',
      slug: 'user_cares',
      screen: ProfileCaresScreen,
      path: '/',
      icon: faCalendarAlt,
      inMenu: true,
      exact: true,
      allowedRoles: { bk: [1,2,4,5], pb: [1,2,4,5] }
    },
    {
      title: 'Profildaten',
      slug: 'user_profiles',
      screen: ProfileDataScreen,
      path: '/profiledata',
      icon: farUser,
      inMenu: true,
      exact: false,
      allowedRoles: { bk: [1,2,4,5,6], pb: [1,2,4,5,6] },
    },
    {
      title: 'Medien & Dokumente',
      slug: 'user_media_documents',
      screen: MediaDocumentsScreen,
      path: '/media-documents',
      icon: faImage,
      inMenu: true,
      exact: false,
      allowedRoles: { bk: [1,2,3,4,5,6], pb: [1,2,3,4,6] },
    },
    {
      title: 'Kommunikation',
      slug: 'user_communication',
      screen: ProfileCommunicationScreen,
      path: '/communication',
      icon: faEnvelope,
      inMenu: true,
      exact: false,
      allowedRoles: { bk: [1,2,3,4,5], pb: [1,2,3,4] },
    },
    {
      title: 'Aufgaben & Notizen',
      slug: 'user_tasks_notices',
      screen: TasksNotesScreen,
      path: '/tasks-notes',
      icon: faTasks,
      inMenu: true,
      exact: false,
      allowedRoles: { bk: [1,2,3,4,5,6], pb: [1,2,3,4,5,6] },
    },
    {
      title: 'Bewertungen',
      slug: 'user_ratings',
      screen: RatingsScreen,
      path: '/ratings',
      icon: faStar,
      inMenu: true,
      exact: false,
      allowedRoles: { bk: [1,2,4,5,6], pb: [1,2,4,6] },
    },
]

export const adminsMenu = [
    {
      title: 'Benutzer',
      slug: 'admins_users',
      screen: UsersAdminsScreen,
      path: '/',
      icon: faUser,
      inMenu: true,
      exact: true,
      allowedRoles: [1],
    },
    {
      title: 'Gruppen',
      slug: 'admins_groups',
      screen: GroupsAdminsScreen,
      path: '/groups',
      icon: faUsers,
      inMenu: true,
      exact: false,
      allowedRoles: [1],
    },
]

export const settingsMenu = [
    {
      title: 'Kommunikation',
      slug: 'settings_communication',
      screen: CommunicationSettingsScreen,
      path: '/',
      icon: faEnvelopeOpenText,
      inMenu: true,
      exact: true,
      allowedRoles: [1,2],
    },
    {
      title: 'Import/Export',
      slug: 'settings_import',
      screen: ImportSettingsScreen,
      path: '/import',
      icon: faFileImport,
      inMenu: true,
      exact: false,
      allowedRoles: [1],
    },
]
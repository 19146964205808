import React, { useEffect, useState } from 'react'
import '../App.css'
import Placeholder from '../images/placeholder.png'
import { authAxios } from '../scripts/Axios'

function ProfileImage({id, style = {}, onClick = null, reload = null }) {

    const [styles, setStyles] = useState({
        ...style,
        //flex: 1,
        cursor: 'pointer',
        opacity: 0,
        transition: 'opacity .4s ease',
    })
    const classNames = true ? 'media ' : 'media-full'
    const [url, setUrl] = useState()

    useEffect(() => {
        if(id) loadImage()
    }, [id, reload])

    useEffect(() => {
        if(!url) return
        setStyles({
            ...styles,
            opacity: 1,
            backgroundImage: 'url(' + url + ')',
        })
    }, [url])

    const loadImage = async () => {
        let image = await authAxios.get('admin-profile-image/' + id, {responseType: 'blob', timeout: 30000})
        if(image.status === 200){
            if(image.data.type != 'text/html'){ // meh
                setUrl(URL.createObjectURL(image.data))
            }
            else setUrl(Placeholder)
        }
    }

    function clickImage(){
        if(onClick) onClick()
    }

    return ( 
        <div onClick={clickImage} className={classNames} style={styles}></div>
    )

}

export default ProfileImage
import React, { createContext, useState } from 'react'

export const AppContext = createContext(null)

export default ({children}) => {

    const [loading, setLoading] = useState(true)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [reloadProfileImage, setReloadProfileImage] = useState()

    const [pageSubtitle, setPageSubtitle] = useState('')

    const data = {
        loading, setLoading,
        dataLoaded, setDataLoaded,
        reloadProfileImage, setReloadProfileImage,
        pageSubtitle, setPageSubtitle,
    }

    return (
        <AppContext.Provider value={data}>
            {children}
        </AppContext.Provider>
    )
    
}
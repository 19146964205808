import React, { useEffect, useState, useContext } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { authAxios } from '../scripts/Axios'

import { AppContext } from '../scripts/AppContext'
import { UserContext } from '../scripts/UserContext'

import Window from '../components/Window'
import Table from '../components/Table'
import Alert from '../components/Alert'

function CaresScreen() {

    const history = useHistory()
    const [alertMessage, setAlertMessage] = useState(null)

    const { setLoading } = useContext(AppContext)
    const { user: admin } = useContext(UserContext)

    const [newCares, setNewCares] = useState([])
    const [currentCares, setCurrentCares] = useState([])
    const [upcomingCares, setUpcomingCares] = useState([])


    useEffect(() => {

        async function getCaresData(){

            setLoading(true)

            const getNewCares = authAxios.get('new-cares')
            const getCurrentCares = authAxios.get('current-cares')
            const getUpcomingCares = authAxios.get('upcoming-cares')
            
            await Axios.all([
                getNewCares,
                getCurrentCares,
                getUpcomingCares
            ]).then(Axios.spread(function(response1, response2, response3) {

                if(response1.data.type === 'error'){
                    setAlertMessage({ type: response1.data.type, message: response1.data.message })
                }else{
                    setNewCares(response1.data.cares)
                }

                if(response2.data.type === 'error'){
                    setAlertMessage({ type: response2.data.type, message: response2.data.message })
                }else{
                    setCurrentCares(response2.data.cares)
                }

                if(response3.data.type === 'error'){
                    setAlertMessage({ type: response3.data.type, message: response3.data.message })
                }else{
                    setUpcomingCares(response3.data.cares)
                }

                setLoading(false)

            }))
        
        }

        getCaresData()

    }, [])


    function handleEditUser(id){
        history.push('/user/' + id)
    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Alert data={alertMessage} />

                <div className="window-row">
                    <div className="col">

                        <Window name="newCares" title={'Neue Betreuungen'} actions={newCares.length !== 0 && <span className="count">{newCares.length}</span>}>

                            <Table name="tableNewCares" data={newCares} headers={[
                                {
                                    field: 'status',
                                    label: 'Status',
                                    type: 'carestatus',
                                    size: 's',
                                },
                                {
                                    field: 'created_at',
                                    label: 'Erstellt am',
                                    type: 'datetime',
                                    size: 'm',
                                },
                                {
                                    field: 'startdatum',
                                    label: 'Startdatum',
                                    type: 'date',
                                    size: 'm',
                                },
                                {
                                    field: 'pb_user_id',
                                    label: 'Kunde',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'bk_user_id',
                                    label: 'BK',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'origin',
                                    label: 'Ursprung',
                                    type: 'origin',
                                    size: 'm',
                                },
                            ]} onEdit={ [1,2,3,4,6].includes(admin.role) ? handleEditUser : null} editIdField="pb_user_id" />

                        </Window>

                    </div>
                </div>

                <div className="window-row">
                    <div className="col">

                        <Window name="currentCares" title={currentCares.length + ' Laufende Betreuungen'}>

                            <Table name="tableCurrentCares" data={currentCares} headers={[
                                {
                                    field: 'status',
                                    label: 'Status',
                                    type: 'carestatus',
                                    size: 's',
                                },
                                {
                                    field: 'startdatum',
                                    label: 'Startdatum',
                                    type: 'date',
                                    size: 'm',
                                },
                                {
                                    field: 'enddatum',
                                    label: 'Enddatum',
                                    type: 'date',
                                    size: 'm',
                                },
                                {
                                    field: 'pb_user_id',
                                    label: 'Kunde',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'bk_user_id',
                                    label: 'BK',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'origin',
                                    label: 'Ursprung',
                                    type: 'origin',
                                    size: 'm',
                                },
                            ]} onEdit={ [1,2,3,4,6].includes(admin.role) ? handleEditUser : null } editIdField="pb_user_id" />

                        </Window>

                    </div>
                </div>

                <div className="window-row">
                    <div className="col">

                        <Window name="upcomingCares" title={upcomingCares.length + ' Anstehende Betreuungen'}>

                            <Table name="tableUpcomingCares" data={upcomingCares} headers={[
                                {
                                    field: 'status',
                                    label: 'Status',
                                    type: 'carestatus',
                                    size: 's',
                                },
                                {
                                    field: 'startdatum',
                                    label: 'Startdatum',
                                    type: 'date',
                                    size: 'm',
                                },
                                {
                                    field: 'enddatum',
                                    label: 'Enddatum',
                                    type: 'date',
                                    size: 'm',
                                },
                                {
                                    field: 'pb_user_id',
                                    label: 'Kunde',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'bk_user_id',
                                    label: 'BK',
                                    type: 'user',
                                    size: 'l',
                                },
                                {
                                    field: 'origin',
                                    label: 'Ursprung',
                                    type: 'origin',
                                    size: 'm',
                                },
                            ]} onEdit={ [1,2,3,4,6].includes(admin.role) ? handleEditUser : null } editIdField="pb_user_id" />

                        </Window>

                    </div>
                </div>


            </div>
        </Scrollbars>
    )

}

export default CaresScreen
import React, { useEffect } from 'react'
import '../App.css'

function RangeInput({min, max, step, name, state, setState}) {

    useEffect(() => {
        if(!state[name]) setState({ ...state, [name]: min })
    }, [])

    return (
        <div className="rangeInput">
            <input type="number" min={min} max={max} step={step} value={state[name]} onChange={e => setState({ ...state, [name]: e.target.value })} />  
            <input type="range" min={min} max={max} step={step} value={state[name]} onChange={e => setState({ ...state, [name]: e.target.value })} />
        </div>
    )

}

export default RangeInput
import React, { useContext, useState } from 'react'
import '../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { authAxios } from '../../scripts/Axios'

import Window from '../../components/Window'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import Alert from '../../components/Alert'
import Selectbox from '../../components/Selectbox'

import { AppContext } from '../../scripts/AppContext'
import { DataContext } from '../../scripts/DataContext'


function GroupsAdminsScreen() {

    const { setLoading } = useContext(AppContext)
    const { admins, groups, setGroups } = useContext(DataContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [editID, setEditID] = useState(null)
    const [addInputs, setAddInputs] = useState(null)
    const [editInputs, setEditInputs] = useState(null)
    
    function handleAddGroup(){
        setAddModal(true)
        setAddInputs({
            name: '',
            members: [],
        })
    }

    function saveAddGroup(){

        setLoading(true)
        
        authAxios.post('group', addInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Erstellt!' })
                setAddModal(false)
                setGroups([...groups, response.data.group])
            }

        })
        .catch(error => {
            console.log(error)
        })

    }


    function handleEditGroup(id){
        setEditModal(true)
        setEditID(id)
        let group = groups.find(group => group.id === id)
        setEditInputs({
            name: group.name,
            members: group.members.map(member => member.id)
        })
    }

    function saveEditGroup(){

        setLoading(true)
        
        authAxios.put('group/' + editID, editInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditModal(false)
                setGroups(groups.map(group => group.id === response.data.group.id ? response.data.group : group))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function handleDeleteGroup(){
        
        let q = window.confirm('Diese Gruppe wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('group/' + editID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditModal(false)
                    setGroups(groups.filter(group => group.id !== editID))
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }


    return (
        <div className="page-content">

            <Alert data={alertMessage} />

            <Window name="adminGroupList" title={groups.length + ' Gruppen'} actions={
                <div style={{marginLeft:'auto'}}>
                    <button onClick={e => handleAddGroup()}><FontAwesomeIcon icon={faPlus} /> Gruppe erstellen</button>
                </div>
            }>

                <Table name="tableAdminGroups" data={groups} headers={[
                    {
                        field: 'name',
                        label: 'Gruppenname',
                        type: 'text',
                        size: 'xl',
                    },
                    {
                        field: 'member_count',
                        label: 'Mitglieder',
                        type: 'number',
                        size: 'm',
                    }
                ]} actions={{
                    edit: true,
                }} onEdit={handleEditGroup} />

            </Window>

            { addModal &&
                <Modal title="Gruppe erstellen" classes={['small']} toggle={setAddModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddGroup()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="row">
                        <div className="col">
                            <label>Gruppenname<br />
                            <input type="text" value={addInputs.name} onChange={e => setAddInputs({...addInputs, name: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Mitglieder<br />
                            <Selectbox options={admins.filter(admin => admin.konto != 0).map(admin => ({label: admin.vorname+' '+admin.nachname, value: admin.id}))} name="members" multiple={true} state={addInputs} setState={setAddInputs} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { editModal &&
                <Modal title="Gruppe bearbeiten" classes={['small']} toggle={setEditModal} actions={
                    <div className="modal-actions">
                        <button className="deleteLink" onClick={e => handleDeleteGroup()}>Gruppe löschen</button>
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditGroup()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    <div className="row">
                        <div className="col">
                            <label>Gruppenname<br />
                            <input type="text" value={editInputs.name} onChange={e => setEditInputs({...editInputs, name: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Mitglieder<br />
                            <Selectbox options={admins.filter(admin => admin.konto != 0).map(admin => ({label: admin.vorname+' '+admin.nachname, value: admin.id}))} name="members" multiple={true} state={editInputs} setState={setEditInputs} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }

        </div>
    )

}

export default GroupsAdminsScreen
import React, { useContext, useEffect, useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFilter, faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router-dom'
import { authAxios } from '../scripts/Axios'
import { updatePref, getPref } from '../scripts/Functions'

import Window from '../components/Window'
import Table from '../components/Table'
import Modal from '../components/Modal'
import Selectbox from '../components/Selectbox'
import AccountStatus from '../components/AccountStatus'
import UserStatus from '../components/UserStatus'

import { AppContext } from '../scripts/AppContext'
import { DataContext } from '../scripts/DataContext'
import { UserContext } from '../scripts/UserContext'

function UserListScreen({ params }) {

    const { setLoading } = useContext(AppContext)
    const { admins, countries, profileOptions, groups } = useContext(DataContext)
    const { user: me, checkScope } = useContext(UserContext)
    const history = useHistory()

    const [users, setUsers] = useState([])
    const [addUserModal, setAddUserModal] = useState(false)
    const [addUserInputs, setAddUserInputs] = useState(null)

    const [showMyUsers, setShowMyUsers] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const filterDefault = {
        search: '',
        konto: [0,1,2,3,4],
        status: [0,1,2],
        invisible: [0,1],
        minPflegegrad: null,
        maxPflegegrad: null,
        minAlter: null,
        maxAlter: null,
        geschlecht: null,
        geschlecht_wunsch: null,
        vertrag: null,
        raucher: null,
        fuehrerschein: null,
        beweglichkeit: null,
        urinkontrolle: null,
        stuhlkontrolle: null,
        bk_deutsch: null,
        wohnort: null,
        wohnsituation: null,
        bk_wohnen: [],
        country: null,
        assigned_to: null,
        group: null,
    }
    const [filters, setFilters] = useState(filterDefault)


    useEffect(() => {

        setLoading(true)

        authAxios.get('users/' + params.user_role)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUsers(response.data.users)
                setFilteredUsers(response.data.users)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        .then(() => setFilterPref())

    }, [])


    async function setFilterPref(){        
        let filterPref = await getPref(params.user_role === 1 ? 'bk_filter' : 'pb_filter')
        if(filterPref !== null){
            filterPref.search = filterPref.search === null ? '' : filterPref.search
            if(filterPref !== null) setFilters(filterPref)
        }
    }

    

    function handleAddUser(){
        setAddUserModal(true)
        setAddUserInputs({
            anrede: '',
            titel: '',
            vorname: '',
            nachname: '',
            telefon: '',
            strasse: '',
            hausnummer: '',
            plz: '',
            ort: '',
            land: '',
        })
    }

    function saveAddUser(){

        setLoading(true)
        
        authAxios.post('user', {
            ...addUserInputs,
            user_role: params.user_role,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                handleEditUser(response.data.id)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }
    
    function handleEditUser(id){
        let url = '/user/'+id
        history.push(url)
    }
    
    function changeShowMyUsers(){
        let state = !showMyUsers
        setShowMyUsers(state)
        updatePref('tableUserListOptions', {
            showMyUsers: state,
        })
    }

    useEffect(() => {

        let filtered = users

        // Text-Search
        filtered = filtered.filter(user => {
            if(filters.search === ''){ return true }
            let isMatching = false
            if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    let searchTags = filters.search.split(' ')
                    searchTags.filter(t => t !== '').map(tag => {
                        if(
                            (profile.vorname ? (profile.vorname.toLowerCase().includes(tag.toLowerCase())) : false) ||
                            (profile.nachname ? (profile.nachname.toLowerCase().includes(tag.toLowerCase())) : false) ||
                            (user.email ? (user.email.toLowerCase().includes(tag.toLowerCase())) : false) ||
                            user.id === parseInt(tag)
                        ){
                            isMatching = true
                        }                        
                    })
                })
            }
            else{
                let searchTags = filters.search.split(' ')
                searchTags.filter(t => t !== '').map(tag => {
                    if((user.email ? (user.email.toLowerCase().includes(tag.toLowerCase())) : false) || user.id === parseInt(tag)){
                        isMatching = true
                    }                        
                })
            }
            return isMatching
        })
        // Konto
        filtered = filtered.filter(user => filters.konto.includes(user.konto))
        // Status
        filtered = filtered.filter(user => filters.status.includes(user.status))
        // Visiblity
        if(filters.invisible) filtered = filtered.filter(user => filters.invisible.includes(user.invisible))
        // Pflegegrad
        filtered = filtered.filter(user => {
            let idOffset = 4 // Gefährlicher Fix!!!
            let isBetween = false
            let min = filters.minPflegegrad ? parseInt(filters.minPflegegrad) + idOffset : 1 + idOffset
            let max = filters.maxPflegegrad ? parseInt(filters.maxPflegegrad) + idOffset : 5 + idOffset
            if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(filters.minPflegegrad && filters.maxPflegegrad){
                        if(profile.pflegegrad >= min && profile.pflegegrad <= max){
                            isBetween = true
                        }
                    }
                    else if(filters.minPflegegrad){
                        if(profile.pflegegrad >= min){
                            isBetween = true
                        }
                    }
                    else if(filters.maxPflegegrad){
                        if(profile.pflegegrad <= max){
                            isBetween = true
                        }
                    }
                    else{
                        isBetween = true
                    }
                })
            }
            else isBetween = true
            return isBetween
        })
        // Alter
        filtered = filtered.filter(user => {
            let isBetween = false
            let min = filters.minAlter ? parseInt(filters.minAlter) : 0
            let max = filters.maxAlter ? parseInt(filters.maxAlter) : 999
            if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(profile.geburtsdatum === null || (moment().diff(moment(profile.geburtsdatum), 'years') >= min && moment().diff(moment(profile.geburtsdatum), 'years') <= max)){
                        isBetween = true
                    }
                })
            }
            else isBetween = true
            return isBetween
        })
        // Geschlecht
        filtered = filtered.filter(user => {
            let isSame = false
            if(!filters.geschlecht){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(profile.anrede === filters.geschlecht){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Bevorzugtes Geschlecht
        filtered = filtered.filter(user => {
            let isSame = false
            if(!filters.geschlecht_wunsch){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    let parsedArray = JSON.parse(profile.bk_anrede)
                    if(Array.isArray(parsedArray) && parsedArray.includes(filters.geschlecht_wunsch)){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Vertrag
        filtered = filtered.filter(user => {
            let isSame = false
            if(!filters.vertrag){
                isSame = true
            }
            else if(user.contract === filters.vertrag){
                isSame = true
            }
            return isSame
        })
        // Raucher
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.raucher === null){
                isSame = true
            }
            else if(user.user_role === 1){
                let isID = filters.raucher === 0 ? 123 : 122
                if(user.profiles.length > 0){
                    user.profiles.map(profile => {
                        if(parseInt(profile.bk_raucher) === isID){
                            isSame = true
                        }
                    })
                }
                else isSame = true
            }else{
                let isID = filters.raucher === 0 ? 111 : 112
                if(user.profiles.length > 0){
                    user.profiles.map(profile => {
                        if(parseInt(profile.raucher) === isID){
                            isSame = true
                        }
                    })
                }
                else isSame = true
            }
            return isSame
        })
        // Führerschein
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.fuehrerschein === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                let isID = filters.fuehrerschein === 0 ? 125 : 124
                user.profiles.map(profile => {
                    if(parseInt(profile.bk_fuehrerschein) === isID){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Beweglichkeit
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.beweglichkeit === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(parseInt(profile.beweglichkeit) <= filters.beweglichkeit){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Urinkontrolle
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.urinkontrolle === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(parseInt(profile.urinkontrolle) <= filters.urinkontrolle){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Stuhlkontrolle
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.stuhlkontrolle === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(parseInt(profile.stuhlkontrolle) <= filters.stuhlkontrolle){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Sprachkenntnisse
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.bk_deutsch === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(parseInt(profile.bk_deutsch) === filters.bk_deutsch){
                        isSame = true
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Wohnort
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.wohnort === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(params.user_role === 1){
                        if(Array.isArray(profile.wohnort)){
                            if(profile.wohnort.includes(filters.wohnort)){
                                isSame = true
                            }
                        }
                    }else{
                        if(parseInt(profile.wohnort) === filters.wohnort){
                            isSame = true
                        }
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Wohnsituation
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.wohnsituation === null){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(params.user_role === 1){
                        if(Array.isArray(profile.wohnsituation)){
                            if(profile.wohnsituation.includes(filters.wohnsituation)){
                                isSame = true
                            }
                        }
                    }else{
                        if(parseInt(profile.wohnsituation) === filters.wohnsituation){
                            isSame = true
                        }
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // BK Wohnung
        filtered = filtered.filter(user => {
            let isSame = false
            if(filters.bk_wohnen === null || filters.bk_wohnen.length === 0){
                isSame = true
            }
            else if(user.profiles.length > 0){
                user.profiles.map(profile => {
                    if(profile.bk_wohnen !== null){
                        if(profile.bk_wohnen.length > 0){
                             let tagsIncluded = filters.bk_wohnen.every((val) => profile.bk_wohnen.includes(val))
                             if(tagsIncluded){
                                isSame = true
                             }
                        }
                    }
                })
            }
            else isSame = true
            return isSame
        })
        // Country
        filtered = filtered.filter(user => {
            let isSame = false
            if(!filters.country){
                isSame = true
            }
            else if(user.land === filters.country){
                isSame = true
            }
            return isSame
        })
        // Assigned To
        filtered = filtered.filter(user => {
            let isSame = true
            if(filters.assigned_to){
                isSame = parseInt(user.assigned_to) === filters.assigned_to
            }
            return isSame
        })
        // Group
        filtered = filtered.filter(user => {
            let isSame = true
            if(filters.group){
                isSame = parseInt(user.group_id) === filters.group
            }
            return isSame
        })

        // Only show my assigned users
        if(showMyUsers){
            filtered = filteredUsers.filter(user => parseInt(user.assigned_to) === me.id)
        }

        setFilteredUsers(filtered)

    }, [filters, showMyUsers])


    function changeFilter(key, value){

        let newFilter = null

        if(
            key === 'konto' ||
            key === 'status' ||
            key === 'invisible'
        ){
            if(filters[key] && filters[key].includes(value)){
                // remove
                newFilter = {
                    ...filters,
                    [key]: filters[key].filter(val => val !== value),
                }
                setFilters(newFilter)
            }else{
                // add
                newFilter = {
                    ...filters,
                    [key]: filters[key] ? [...filters[key], value] : [value],
                }
                setFilters(newFilter)
            }
        }
        else {
            newFilter = {
                ...filters,
                [key]: value !== '' ? value : null,
            }
            setFilters(newFilter)
        }

        updatePref(params.user_role === 1 ? 'bk_filter' : 'pb_filter', newFilter)

    }

    function setTextFilter(val){
        setFilters({
            ...filters,
            search: val,
        })
    }

    function resetFilter(){
        setFilters(filterDefault)
        updatePref(params.user_role === 1 ? 'bk_filter' : 'pb_filter', filterDefault)
    }

    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <Window name="userList" title={params.user_role === 1 ? filteredUsers.length + ' Betreuungskräfte' : filteredUsers.length + ' Pflegebedürftige'} actions={
                    <div style={{flexDirection:'row', marginLeft:'auto'}}>
                        <input type="search" className="search-input" placeholder="Suche ..." onChange={e => setTextFilter(e.target.value)} />
                        <button onClick={e => setShowFilter(!showFilter)}><FontAwesomeIcon icon={faFilter} /> {!showFilter ? 'Filter anzeigen' : 'Filter verbergen'}</button>
                        { checkScope('createBK') && params.user_role === 1 ? (
                            <button onClick={e => handleAddUser()}><FontAwesomeIcon icon={faPlus} /> Betreuungskraft erstellen</button>
                        ) : null }
                        { checkScope('createPB') && params.user_role === 2 ? (
                            <button onClick={e => handleAddUser()}><FontAwesomeIcon icon={faPlus} /> Pflegebedürftigen erstellen</button>
                        ) : null }
                    </div>
                }>

                    { showFilter &&
                    <div className="filters">
                        
                        <div className="row" style={{width:'fit-content'}}>
                            <div className="col" style={{marginRight:20}}>

                                <label>Konto-Status</label>
                                <div className="checkContainer" style={{marginTop:10}}>
                                    <input type="checkbox" checked={filters.konto.includes(0)} onChange={() => changeFilter('konto', 0)} />
                                    <AccountStatus value={0} />
                                    <span>Konto gesperrt</span>
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.konto.includes(1)} onChange={() => changeFilter('konto', 1)} />
                                    <AccountStatus value={1} />
                                    <span>Profilerstellung</span>
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.konto.includes(2)} onChange={() => changeFilter('konto', 2)} />
                                    <AccountStatus value={2} />
                                    <span>Neu registriert</span>
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.konto.includes(3)} onChange={() => changeFilter('konto', 3)} />
                                    <AccountStatus value={3} />
                                    <span>In Übersetzung</span>
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.konto.includes(4)} onChange={() => changeFilter('konto', 4)} />
                                    <AccountStatus value={4} />
                                    <span>Konto aktiv</span>
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.konto.includes(5)} onChange={() => changeFilter('konto', 5)} />
                                    <AccountStatus value={5} />
                                    <span>Archiviert</span>
                                </div>

                            </div>
                            <div className="col" style={{marginRight:20}}>

                                <label>Arbeits-Status</label>
                                <div className="checkContainer" style={{marginTop:10}}>
                                    <input type="checkbox" checked={filters.status.includes(0)} onChange={() => changeFilter('status', 0)} />
                                    <UserStatus value={0} />
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.status.includes(1)} onChange={() => changeFilter('status', 1)} />
                                    <UserStatus value={1} />
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.status.includes(2)} onChange={() => changeFilter('status', 2)} />
                                    <UserStatus value={2} />
                                </div>

                                <br />

                                <label>Sichtbarkeit</label>
                                <div className="checkContainer" style={{marginTop:10}}>
                                    <input type="checkbox" checked={filters.invisible && filters.invisible.includes(0)} onChange={() => changeFilter('invisible', 0)} />
                                    <FontAwesomeIcon icon={faEye} color="#d6e1e9" />
                                </div>
                                <div className="checkContainer">
                                    <input type="checkbox" checked={filters.invisible && filters.invisible.includes(1)} onChange={() => changeFilter('invisible', 1)} />
                                    <FontAwesomeIcon icon={faEyeSlash} color="#d6e1e9" />
                                </div>

                            </div>
                            <div className="col" style={{marginRight:20, minWidth: 150}}>

                                <label>Pflegegrad</label>
                                <div className="row">
                                    <div className="col">
                                        <input type="number" min={1} max={5} placeholder="Min." value={filters.minPflegegrad || ''} onChange={e => changeFilter('minPflegegrad', e.target.value)} style={{backgroundColor:'#fff'}} />
                                    </div>
                                    <div className="col">
                                        <input type="number" min={1} max={5} placeholder="Max." value={filters.maxPflegegrad || ''} onChange={e => changeFilter('maxPflegegrad', e.target.value)} style={{backgroundColor:'#fff'}} />
                                    </div>
                                </div>
                                
                                <label>Alter</label>
                                <div className="row">
                                    <div className="col">
                                        <input type="number" min={0} max={999} placeholder="Min." value={filters.minAlter || ''} onChange={e => changeFilter('minAlter', e.target.value)} style={{backgroundColor:'#fff'}} />
                                    </div>
                                    <div className="col">
                                        <input type="number" min={0} max={999} placeholder="Max." value={filters.maxAlter || ''} onChange={e => changeFilter('maxAlter', e.target.value)} style={{backgroundColor:'#fff'}} />
                                    </div>
                                </div>

                                <label>Geschlecht</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Alle', value: null },
                                            { label: 'Männlich', value: 'Herr' },
                                            { label: 'Weiblich', value: 'Frau' },
                                            { label: 'Divers', value: 'Divers' },
                                        ]} name="geschlecht" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Bevorzugtes Geschlecht</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Alle', value: null },
                                            { label: 'Männlich', value: 118 },
                                            { label: 'Weiblich', value: 117 },
                                        ]} name="geschlecht_wunsch" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                            </div>
                            <div className="col" style={{marginRight:20, minWidth:200}}>

                                <label>Land</label>
                                <div className="row">
                                    <div className="col">                                        
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...Object.keys(countries).map(code => ({
                                                label: countries[code],
                                                value: code,
                                            }))
                                        ]} name="country" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Vertrag</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            { label: 'Deutscher Pflegedienstvertrag', value: 'dpfv' },
                                            { label: 'Polnischer Vertrag', value: 'pv' },
                                            { label: 'Deutscher & Polnischer Vertrag', value: 'd+pv' },
                                        ]} name="vertrag" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Raucher</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            { label: 'Ja', value: 1 },
                                            { label: 'Nein', value: 0 },
                                        ]} name="raucher" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />  
                                    </div>
                                </div> 

                                <label>Führerschein</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            { label: 'Ja', value: 1 },
                                            { label: 'Nein', value: 0 },
                                        ]} name="fuehrerschein" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                            </div>
                            <div className="col" style={{marginRight:20, minWidth:300}}>

                                <label>Zugewiesener Mitarbeiter</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...admins.map(admin => ({ label: admin.vorname + ' ' + admin.nachname, value: admin.id }))
                                        ]} name="assigned_to" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Gruppe</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...groups.map(group => ({ label: group.name, value: group.id }))
                                        ]} name="group" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Beweglichkeit</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...profileOptions.filter(opt => opt.name === 'beweglichkeit')
                                        ]} name="beweglichkeit" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Urinkontrolle</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...profileOptions.filter(opt => opt.name === 'urinkontrolle')
                                        ]} name="urinkontrolle" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Stuhlkontrolle</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...profileOptions.filter(opt => opt.name === 'stuhlkontrolle')
                                        ]} name="stuhlkontrolle" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col" style={{marginRight:20, minWidth:300, maxWidth:630}}>

                                <label>Sprachkenntnisse</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...profileOptions.filter(opt => opt.name === 'bk_deutsch')
                                        ]} name="bk_deutsch" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Wohnort</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...profileOptions.filter(opt => opt.name === 'wohnort')
                                        ]} name="wohnort" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>Wohnsituation</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={[
                                            { label: 'Egal', value: null },
                                            ...profileOptions.filter(opt => opt.name === 'wohnsituation')
                                        ]} name="wohnsituation" state={filters} setState={changeFilter} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>

                                <label>BK Wohnung</label>
                                <div className="row">
                                    <div className="col">
                                        <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_wohnen')} name="bk_wohnen" state={filters} setState={changeFilter} multiple={true} isFilter={true} bgColor="#fff" />
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                                
                        <button onClick={e => resetFilter()} className="deleteLink" style={{paddingTop:12, paddingBottom:12, paddingLeft:6, paddingRight:6, height: 'auto'}}><FontAwesomeIcon icon={faTimes} />Filter zurücksetzen</button>

                    </div>
                    }


                    <Table name={params.user_role === 1 ? 'tableBKs' : 'tablePBs'} data={filteredUsers} perPage={25} headers={[
                        {
                            field: 'konto',
                            label: '',
                            type: 'account',
                            size: 'xxs',
                        },
                        {
                            field: 'invisible',
                            label: 'Sichtbarkeit',
                            labelAsIcon: true,
                            labelIcon: faEye,
                            type: 'visibility',
                            size: 'xxs',
                        },
                        {
                            field: 'status',
                            label: 'Status',
                            type: 'status',
                            size: 's',
                        },
                        /* {
                            field: 'anrede',
                            label: 'Anrede',
                            type: 'text',
                            size: 's',
                            profile: true,
                        }, */
                        {
                            field: 'vorname',
                            label: 'Vorname',
                            type: 'text',
                            size: 'm',
                            profile: true,
                        },
                        {
                            field: 'nachname',
                            label: 'Nachname',
                            type: 'text',
                            size: 'm',
                            profile: true,
                        },
                        {
                            field: 'pflegegrad',
                            label: 'Pflegegrad',
                            type: 'carelevel',
                            size: 's',
                            profile: true,
                        },
                        {
                            field: 'contract',
                            label: 'Vertrag',
                            type: 'contract',
                            size: 's',
                        },
                        {
                            field: 'land',
                            label: 'Land',
                            type: 'country',
                            size: 'm',
                        },
                        /* {
                            field: 'geburtsdatum',
                            label: 'Alter',
                            type: 'age',
                            size: 'xs',
                            profile: true,
                        },
                        {
                            field: 'email',
                            label: 'E-Mail',
                            type: checkScope('viewPBContacts', { 'user_role': params.user_role }) ? 'email' : 'hidden',
                            size: 'm',
                            profile: false,
                        }, */
                        /* {
                            field: 'mobile',
                            label: 'Handynummer',
                            type: checkScope('viewPBContacts', { 'user_role': params.user_role }) ? 'text' : 'hidden',
                            size: 'm',
                            profile: false,
                        }, */
                        /* {
                            field: 'next_break_start',
                            label: 'Pause von',
                            type: 'date',
                            size: 's',
                            profile: false,
                        },
                        {
                            field: 'next_break_end',
                            label: 'Pause bis',
                            type: 'date',
                            size: 's',
                            profile: false,
                        }, */
                        {
                            field: 'startdatum',
                            label: 'Verfügbar',
                            type: 'date',
                            size: 's',
                            profile: false,
                        },
                        {
                            label: 'Ursprung',
                            field: 'origin',
                            type: 'origin',
                            size: 's',
                        },
                        {
                            label: params.user_role === 1 ? 'Recruiter' : 'Sales',
                            field: 'assigned_to',
                            type: 'admin',
                            size: 'l',
                        },
                    ]} onEdit={handleEditUser} footerContent={
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <label className="checkContainer">
                                <input type="checkbox" checked={showMyUsers} onChange={changeShowMyUsers} /> <span>Nur meine User anzeigen</span>
                            </label>
                        </div>
                    } />

                </Window>

                
                { addUserModal &&
                    <Modal title={params.user_role === 1 ? 'Betreuungskraft erstellen' : 'Pflegebedürftigen erstellen'} toggle={setAddUserModal} actions={
                        <div className="modal-actions">
                            <div style={{marginLeft:'auto'}}>
                                <button className="cancelButton" onClick={e => setAddUserModal(false)}>Abbrechen</button>
                                <button className="saveButton" onClick={e => saveAddUser()}><FontAwesomeIcon icon={faPlus} /> Erstellen</button>
                            </div>
                        </div>
                    }>

                        <div className="row">
                            <div className="col">
                                <label>Anrede<br />
                                <Selectbox options={[
                                    { label: 'Herr', value: 'Herr' },
                                    { label: 'Frau', value: 'Frau' },
                                ]} name="anrede" state={addUserInputs} setState={setAddUserInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Titel<br />
                                <input type="text" value={addUserInputs.titel} onChange={e => setAddUserInputs({...addUserInputs, titel: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Vorname<br />
                                <input type="text" value={addUserInputs.vorname} onChange={e => setAddUserInputs({...addUserInputs, vorname: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Nachname<br />
                                <input type="text" value={addUserInputs.nachname} onChange={e => setAddUserInputs({...addUserInputs, nachname: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Strasse<br />
                                <input type="text" value={addUserInputs.strasse} onChange={e => setAddUserInputs({...addUserInputs, strasse: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Hausnummer<br />
                                <input type="text" value={addUserInputs.hausnummer} onChange={e => setAddUserInputs({...addUserInputs, hausnummer: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>PLZ<br />
                                <input type="text" value={addUserInputs.plz} onChange={e => setAddUserInputs({...addUserInputs, plz: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Ort<br />
                                <input type="text" value={addUserInputs.ort} onChange={e => setAddUserInputs({...addUserInputs, ort: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Land<br />
                                <Selectbox options={Object.keys(countries).map(code => {
                                    return {
                                        label: countries[code],
                                        value: code,
                                    }
                                })} name="land" state={addUserInputs} setState={setAddUserInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Telefon<br />
                                <input type="text" value={addUserInputs.telefon} onChange={e => setAddUserInputs({...addUserInputs, telefon: e.target.value})} />
                                </label>
                            </div>
                        </div>

                    </Modal>
                }

            </div>
        </Scrollbars>
    )

}

export default UserListScreen
import React, { useEffect, useState } from 'react'
import '../App.css'

function MaxTextarea({ max, label, name, state, setState }) {

    const [currentLength, setCurrentLength] = useState(0)

    useEffect(() => {
        if(state[name]) setCurrentLength(state[name].length)
    }, [])

    function changeValue(e){
        if(e.target.value) setCurrentLength(e.target.value.length)
        setState({
            ...state,
            [name]: e.target.value,
        })
    }

    return (
        <div>
            <label>{ label }<br />
            <textarea value={state[name]} onChange={e => changeValue(e)} maxLength={max}></textarea>
            </label>
            <div className="maxInputChars">{ currentLength }/{ max }</div>
        </div>
    )

}

export default MaxTextarea
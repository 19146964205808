import React from 'react'
import '../App.css'

function TaskStatus({value}) {

    const states = [
        {
            className: 'new',
            label: 'Offen',
        },
        {
            className: 'finished',
            label: 'Erledigt',
        },
        {
            className: 'active',
            label: 'In Bearbeitung',
        },
    ]

    return states[value] ? (
        <div className={'task-status ' + states[value].className}>
            {states[value].label}
        </div>
    ) : null

}

export default TaskStatus
import React from 'react'
import './App.css'

import AppContext from './scripts/AppContext'
import UserContext from './scripts/UserContext'
import Navigation from './screens/Navigation'

function App() {

  document.title = 'Senihelp24 Backend'

  return (
    <AppContext>
    <UserContext>
      <Navigation />
    </UserContext>
    </AppContext>
  )

}

export default App

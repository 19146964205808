import React, { useState, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import '../../App.css'
import LoadingIcon from '../../images/loading.svg'

import { AppContext } from '../../scripts/AppContext'
import { axios } from '../../scripts/Axios'

function ForgotPassword() {

    const { loading, setLoading } = useContext(AppContext)

    const [email, setEmail] = useState('')
    const [errorMsg, setErrorMsg] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)

    useEffect(() => setLoading(false), [])

    const forgotPassword = () => {

        setLoading(true)
        setSuccessMsg(null)
        setErrorMsg(null)

        axios.post('forgot-password', {
            email: email,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setErrorMsg(response.data.message)
            }else{
                setSuccessMsg(response.data.message)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    const handleKeys = (e) => {
        if(e.key === 'Enter'){
            forgotPassword()
        }
    }

    return (
        <div id="login-wrapper">
            <div id="login-container">
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} onKeyPress={e => handleKeys(e)} placeholder="E-Mail" />
                <button onClick={e => forgotPassword()}>{ loading ? <img src={LoadingIcon} alt="Loading" /> : 'Passwort zurücksetzen' }</button>
                <NavLink className="light-link" exact={true} to="/" title="Passwort vergessen">Zurück zum Login</NavLink>

                { errorMsg && <div className="message error">{ errorMsg }</div> }
                { successMsg && <div className="message success">{ successMsg }</div> }
            </div>
        </div>
    )

}

export default ForgotPassword
import React from 'react'
import '../App.css'

function AccountStatus({value}) {

    const states = [
        '#E5614F', // 0 = Gesperrt
        '#f1a930', // 1 = Profileingabe
        '#2798D8', // 2 = Neu/Registriert
        '#b845ec', // 3 = In Übersetzung
        '#2FC08C', // 4 = Aktiv
        '#7B919D', // 5 = Archiv
    ]

    return (
        <div className="account-status" style={{ backgroundColor: states[value] }}></div>
    )

}

export default AccountStatus
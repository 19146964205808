import React, { useContext, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import '../App.css'
import { AppContext } from '../scripts/AppContext'
import { UserContext } from '../scripts/UserContext'
import { authAxios } from '../scripts/Axios'
import { useOutsideClick } from '../scripts/Functions'

import ProfileImage from './ProfileImage'

function ProfileMenu() {

    const history = useHistory()

    const { setLoading, setDataLoaded, reloadProfileImage } = useContext(AppContext)
    const { user, setUser, setToken } = useContext(UserContext)

    const [optionsVisible, setOptionsVisible] = useState(false)

    const refSelector = useRef(null)
    useOutsideClick(refSelector, () => setOptionsVisible(false))

    function logout() {
        
        setLoading(true)

        authAxios.post('logout')
        .then(response => {
        
            setLoading(false)
            setDataLoaded(false)
            setUser(null)
            setToken(null)
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            window.location.href = '/'

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <div id="profileMenu" className="account-status-selector noselect">
            <div className="myself" onClick={e => setOptionsVisible(!optionsVisible)}>
                <span>{ user.vorname + ' ' + user.nachname }</span>
                { user.profile_image ? 
                    <ProfileImage id={user.id} reload={reloadProfileImage} style={{ width: 40, borderRadius: 20 }} /> :
                    <div id="profileImage">{ user.vorname[0] + user.nachname[0] }</div>
                }
            </div>
            { optionsVisible &&
            <div ref={refSelector} className="account-status-options">
                <div className="account-status-option" onClick={e => {
                    history.push('/profile')
                    setOptionsVisible(false)
                }}>
                    <FontAwesomeIcon icon={faUserAlt} /> Mein Profil
                </div>
                <div className="account-status-option" onClick={e => logout()}>
                    <FontAwesomeIcon icon={faDoorOpen} /> Ausloggen
                </div>
            </div>
            }
        </div>
    )

}

export default ProfileMenu
import React, { useState, useContext } from 'react'
import '../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { AppContext } from '../../scripts/AppContext'
import { DataContext } from '../../scripts/DataContext'
import { UserContext } from '../../scripts/UserContext'
import { authAxios } from '../../scripts/Axios'
import { ProfileField, LanguageBar, Country, bkContracts, pbContracts } from '../../scripts/Functions'

import Window from '../../components/Window'
import Modal from '../../components/Modal'
import Table from '../../components/Table'
import Selectbox from '../../components/Selectbox'
import Badge from '../../components/Badge'
import MaxTextarea from '../../components/MaxTextarea'
import Alert from '../../components/Alert'
import RangeInput from '../../components/RangeInput'

function ProfileDataScreen({user, setUser}) {

    const { setLoading } = useContext(AppContext)
    const { admins, contactTypes, profileOptions, countries } = useContext(DataContext)
    const { user: admin, checkScope } = useContext(UserContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [activeProfile, setActiveProfile] = useState(0)

    const [addProfileModal, setAddProfileModal] = useState(false)
    const [addProfileInputs, setAddProfileInputs] = useState(null)

    const [editProfileModal, setEditProfileModal] = useState(false)
    const [editProfileID, setEditProfileID] = useState(null)
    const [editProfileInputs, setEditProfileInputs] = useState(null)
    
    const [editAdditionalDataInputs, setEditAdditionalDataInputs] = useState(null)
    const [editAdditionalDataModal, setEditAdditionalDataModal] = useState(false)

    const [editAddressModal, setEditAddressModal] = useState(false)
    const [editAddressInputs, setEditAddressInputs] = useState(null)

    const [addContactModal, setAddContactModal] = useState(false)
    const [addContactInputs, setAddContactInputs] = useState(null)

    const [contactIsAccount, setContactIsAccount] = useState(false)
    const [editContactID, setEditContactID] = useState(null)
    const [editContactModal, setEditContactModal] = useState(false)
    const [editContactInputs, setEditContactInputs] = useState(null)

    const [freetextLang, setFreetextLang] = useState('de')
    
    function handleAddProfile(){
        setAddProfileModal(true)
        setAddProfileInputs({
            anrede: '',
            titel: '',
            vorname: '',
            nachname: '',
            geburtsdatum: '',
            nationalitaet: '',
            familienstand: '',
            raucher: '',
            groesse: '',
            gewicht: '',
            pflegegrad: '',
            krankenkasse: '',
            krankenkasse_typ: '',
            kranken_vnr: '',
            sozial_vnr: '',
            renten_vnr: '',
            steuer_id: '',
            lbnr: '',
            sozialamt_aufstockung: 0,
            qualifikation: '',
            beweglichkeit: '',
            einschraenkungen: '',
            sonstige_einschraenkungen: '',
            besserung: '',
            sinneswahrnehmung: '',
            geisteszustand: '',
            schlafverhalten: '',
            grundpflege: '',
            hygiene: '',
            urinkontrolle: '',
            stuhlkontrolle: '',
            ankleiden: '',
            essen: '',
            wohnsituation: '',
            wohnzustand: '',
            wohnort: '',
            haustiere: '',
            haustiere_welche: '',
            einkaufsmoeglichkeiten: '',
            charaktereigenschaften: '',
            bk_wohnen: '',
            bk_aktivitaeten: '',
            bk_anrede: '',
            bk_deutsch: '',
            //bk_raucher: '',
            bk_fuehrerschein: '',
            hobbys: '',
            umfeld: '',
            sonstiges: '',
        })
    }

    function saveAddProfile(){

        setLoading(true)
        
        authAxios.post('profile', {
            ...addProfileInputs,
            user_id: user.id,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setAddProfileModal(false)
                setActiveProfile(user.profiles.length)
                setUser({
                    ...user,
                    profiles: [
                        ...user.profiles,
                        response.data.profile
                    ],
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    function handleEditProfile(){
        setEditProfileModal(true)
        setEditProfileID(user.profiles[activeProfile].id)
        setEditProfileInputs({
            anrede: user.profiles[activeProfile].anrede,
            titel: user.profiles[activeProfile].titel,
            vorname: user.profiles[activeProfile].vorname,
            nachname: user.profiles[activeProfile].nachname,
            geburtsdatum: user.profiles[activeProfile].geburtsdatum,
            nationalitaet: user.profiles[activeProfile].nationalitaet,
            familienstand: user.profiles[activeProfile].familienstand,
            raucher: user.profiles[activeProfile].raucher,
            groesse: user.profiles[activeProfile].groesse,
            gewicht: user.profiles[activeProfile].gewicht,
            pflegegrad: user.profiles[activeProfile].pflegegrad,
            krankenkasse: user.profiles[activeProfile].krankenkasse,
            krankenkasse_typ: user.profiles[activeProfile].krankenkasse_typ,
            kranken_vnr: user.profiles[activeProfile].kranken_vnr,
            sozial_vnr: user.profiles[activeProfile].sozial_vnr,
            renten_vnr: user.profiles[activeProfile].renten_vnr,
            steuer_id: user.profiles[activeProfile].steuer_id,
            lbnr: user.profiles[activeProfile].lbnr,
            sozialamt_aufstockung: user.profiles[activeProfile].sozialamt_aufstockung,
            qualifikation: user.profiles[activeProfile].qualifikation,
            beweglichkeit: user.profiles[activeProfile].beweglichkeit,
            einschraenkungen: user.profiles[activeProfile].einschraenkungen,
            sonstige_einschraenkungen: user.profiles[activeProfile].sonstige_einschraenkungen,
            besserung: user.profiles[activeProfile].besserung,
            sinneswahrnehmung: user.profiles[activeProfile].sinneswahrnehmung,
            geisteszustand: user.profiles[activeProfile].geisteszustand,
            schlafverhalten: user.profiles[activeProfile].schlafverhalten,
            grundpflege: user.profiles[activeProfile].grundpflege,
            hygiene: user.profiles[activeProfile].hygiene,
            urinkontrolle: user.profiles[activeProfile].urinkontrolle,
            stuhlkontrolle: user.profiles[activeProfile].stuhlkontrolle,
            ankleiden: user.profiles[activeProfile].ankleiden,
            essen: user.profiles[activeProfile].essen,
            wohnsituation: user.profiles[activeProfile].wohnsituation,
            wohnzustand: user.profiles[activeProfile].wohnzustand,
            wohnort: user.profiles[activeProfile].wohnort,
            haustiere: user.profiles[activeProfile].haustiere,
            haustiere_welche: user.profiles[activeProfile].haustiere_welche,
            einkaufsmoeglichkeiten: user.profiles[activeProfile].einkaufsmoeglichkeiten,
            charaktereigenschaften: user.profiles[activeProfile].charaktereigenschaften,
            bk_wohnen: user.profiles[activeProfile].bk_wohnen,
            bk_aktivitaeten: user.profiles[activeProfile].bk_aktivitaeten,
            bk_anrede: user.profiles[activeProfile].bk_anrede,
            bk_deutsch: user.profiles[activeProfile].bk_deutsch,
            //bk_raucher: user.profiles[activeProfile].bk_raucher,
            bk_fuehrerschein: user.profiles[activeProfile].bk_fuehrerschein,
            hobbys: user.profiles[activeProfile].hobbys,
            umfeld: user.profiles[activeProfile].umfeld,
            sonstiges: user.profiles[activeProfile].sonstiges,
        })
    }

    function saveEditProfile(){

        setLoading(true)
        
        authAxios.put('profile/' + editProfileID , editProfileInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditProfileModal(false)
                setUser({
                    ...user,
                    profiles: user.profiles.map(profile => {
                        return editProfileID === profile.id ? response.data.profile : profile
                    })
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleDeleteProfile(){
        
        let q = window.confirm('Dieses Profil wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('profile/' + editProfileID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditProfileModal(false)
                    setActiveProfile(0)
                    setUser({
                        ...user,
                        profiles: user.profiles.filter(p => p.id !== editProfileID)
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    function handleEditAdditionalData(){
        setEditAdditionalDataModal(true)
        setEditAdditionalDataInputs({
            badge: user.badge,
            experience: user.experience,
            german_skills: user.german_skills,
            impfstatus: user.impfstatus,
            description: user.description,
            contract: user.contract,
        })
    }

    function saveAdditionalData(){
        
        setLoading(true)
            
        authAxios.put('user/' + user.id, editAdditionalDataInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditAdditionalDataModal(false)
                setUser({
                    ...user,
                    badge: response.data.user.badge,
                    experience: response.data.user.experience,
                    german_skills: response.data.user.german_skills,
                    impfstatus: response.data.user.impfstatus,
                    description: response.data.user.description,
                    contract: response.data.user.contract,
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        
    }


    function handleEditAddress(){
        setEditAddressModal(true)
        setEditAddressInputs({
            contract: user.contract || '',
            email: user.email || '',
            mobile: user.mobile || '',
            strasse: user.strasse || '',
            hausnummer: user.hausnummer || '',
            plz: user.plz || '',
            ort: user.ort || '',
            land: user.land || '',
            telefon: user.telefon || '',
            wage: user.wage || '',
        })
    }

    function saveEditAddress(){

        if(!checkMobile(editAddressInputs.mobile)){
            setAlertMessage({ type: 'error', message: 'Bitte Handynummer mit Ländervorwahl im korrekten Format eingeben (+49...)'})
            return
        }

        setLoading(true)
        
        authAxios.put('user/' + user.id, editAddressInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{

                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditAddressModal(false)
                
                let updatedUser = response.data.user
                setUser({
                    ...user,
                    contract: updatedUser.contract,
                    email: updatedUser.email,
                    mobile: updatedUser.mobile,
                    telefon: updatedUser.telefon,
                    strasse: updatedUser.strasse,
                    hausnummer: updatedUser.hausnummer,
                    plz: updatedUser.plz,
                    ort: updatedUser.ort,
                    land: updatedUser.land,
                    wage: updatedUser.wage,
                })

            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    function handleAddContact(){
        setAddContactModal(true)
        setAddContactInputs({
            kategorie: [],
            account: 0,
            email: '',
            mobile: '',
            anrede: '',
            titel: '',
            vorname: '',
            nachname: '',
            strasse: '',
            hausnummer: '',
            plz: '',
            ort: '',
            land: '',
            telefon: '',            
        })
    }

    function saveAddContact(){

        if(!checkMobile(addContactInputs.mobile)){
            setAlertMessage({ type: 'error', message: 'Bitte Handynummer mit Ländervorwahl im korrekten Format eingeben (+49...)'})
            return
        }

        setLoading(true)
        
        authAxios.post('contact', {
            user_id: user.id,
            ...addContactInputs,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{

                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setAddContactModal(false)
                
                setUser({
                    ...user,
                    email: response.data.contact.account ? response.data.contact.email : user.email,
                    contacts: [
                        ...user.contacts.map(c => response.data.contact.account === 1 ? {...c, account: 0} : c),
                        response.data.contact,
                    ],
                })

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleEditContact(id){
        setEditContactModal(true)
        setEditContactID(id)
        let contact = user.contacts.find(contact => contact.id === id)
        setContactIsAccount(contact.account === 1 ? true : false)
        setEditContactInputs({
            kategorie: contact.kategorie,
            account: contact.account,
            email: contact.email,
            mobile: contact.mobile,
            anrede: contact.anrede,
            titel: contact.titel,
            vorname: contact.vorname,
            nachname: contact.nachname,
            strasse: contact.strasse,
            hausnummer: contact.hausnummer,
            plz: contact.plz,
            ort: contact.ort,
            land: contact.land,
            telefon: contact.telefon,
        })
    }

    function saveEditContact(){

        if(!checkMobile(editContactInputs.mobile)){
            setAlertMessage({ type: 'error', message: 'Bitte Handynummer mit Ländervorwahl im korrekten Format eingeben (+49...)'})
            return
        }

        setLoading(true)
        
        authAxios.put('contact/' + editContactID, editContactInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{

                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditContactModal(false)

                let contact = response.data.contact
                setUser({
                    ...user,
                    email: contact.account ? contact.email : user.email,
                    contacts: user.contacts.map(c => {
                        if(c.id === contact.id){
                            return contact
                        }else{
                            return contact.account ? {...c, account: 0} : c
                        }
                    })
                })

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleDeleteContact(){
        
        let q = window.confirm('Diese Kontaktperson wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('contact/' + editContactID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                    setEditContactModal(false)
                    setUser({
                        ...user,
                        contacts: user.contacts.filter(c => c.id !== editContactID)
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }


    function checkMobile(number){
        if(!number || number === '') return true
        else if(number.substring(0, 1) === '+') return true
        else return false
    }

    function getContractLabel(slug){
        let contracts = user.user_role === 1 ? bkContracts : pbContracts
        let contract = contracts.find(c => c.value === slug)
        return contract?.label
    }

    function getImpfstatusLabel(id){
        switch(id){
            case null: return 'Unbekannt'
            case 1: return 'Ungeimpft'
            case 2: return 'Geimpft'
            case 3: return 'Booster'
        }
    }

    function getQualificationLabel(slug){
        switch(slug){
            case 'hilfskraft': return 'Hilfskraft'
            case 'ausbildung': return 'In Ausbildung'
            case 'alltagsbegleiter': return 'Geprüfte(r) Alltagsbegleiter*in'
            case 'pflegefachkraft': return 'Examinierte Pflegefachkraft'
        }
    }

    function getTranslatedText(profile, field){
        if(freetextLang != 'de'){
            if(user.translations){
                let translation = user.translations.find(t => t.id === profile.id)
                return translation ? translation[field].replace(/\n/g, '<br />') : ''
            }
            else return ''
        }
        else return profile[field] ? profile[field].replace(/\n/g, '<br />') : ''
    }

    return (
        <div className="page-content">

            <Alert data={alertMessage} />

            <div className="window-row">

                <div className="col">

                    <Window name="userProfile" title="Profil" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ? (
                            <div>
                                { user.profiles.length > 0 && <button onClick={e => handleEditProfile()}><FontAwesomeIcon icon={faPen} /> Bearbeiten</button> }
                                { (user.user_role === 1 && user.profiles.length < 1) || user.user_role === 2 && <button onClick={e => handleAddProfile()}><FontAwesomeIcon icon={faPlus} /> Profil erstellen</button> }
                            </div>
                            ) : null }
                        </div>
                    }>
                        
                    <div className="container">

                        { user.profiles && user.profiles.length > 1 && 
                            <div className="tabs">
                            { user.profiles.map((profile, index) => {
                                return (
                                    <div key={index} className={index === activeProfile ? 'tab active' : 'tab'} onClick={e => setActiveProfile(index)}>Person {index+1}</div>
                                )
                            })}
                            </div>
                        }

                        { user.profiles && user.profiles.map((profile, index) => {
                            return (
                                <div key={profile.id} className="tabContent">
                                { index === activeProfile &&

                                    <div className="window-row">
                                        <div className="col">

                                            <div className="subtitle">Stammdaten</div>
                                            <div className="profile-table">
                                                <div className="row">
                                                    <div className="col">Anrede:</div>
                                                    <div className="col">{profile.anrede}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Titel:</div>
                                                    <div className="col">{profile.titel}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Vorname:</div>
                                                    <div className="col">{profile.vorname}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Nachname:</div>
                                                    <div className="col">{profile.nachname}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Geburtsdatum:</div>
                                                    <div className="col">{ profile.geburtsdatum ? moment(profile.geburtsdatum).format('DD.MM.YYYY') : ''}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Nationalität:</div>
                                                    <div className="col">{profile.nationalitaet}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Größe:</div>
                                                    <div className="col">{profile.groesse} cm</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Gewicht:</div>
                                                    <div className="col">{profile.gewicht} kg</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Familienstand:</div>
                                                    <div className="col">{ProfileField(profile.familienstand)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Raucher:</div>
                                                    <div className="col">{ProfileField(profile.raucher)}</div>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            
                                            <div className="subtitle">Versicherungsdaten</div>
                                            <div className="profile-table" style={{ marginBottom: 15 }}>
                                                <div className="row">
                                                    <div className="col">Krankenkasse:</div>
                                                    <div className="col">{profile.krankenkasse} { profile.krankenkasse_typ ? <span>({profile.krankenkasse_typ.toUpperCase()})</span> : null }</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Krankenversicherungsnr.:</div>
                                                    <div className="col">{profile.kranken_vnr}</div>
                                                </div>
                                                { user.user_role === 1 &&
                                                <div className="row">
                                                    <div className="col">Sozialversicherungsnr.:</div>
                                                    <div className="col">{profile.sozial_vnr}</div>
                                                </div>
                                                }
                                                { user.user_role === 2 &&
                                                <div className="row">
                                                    <div className="col">Rentenversicherungsnr.:</div>
                                                    <div className="col">{profile.renten_vnr}</div>
                                                </div>
                                                }
                                                { user.user_role === 1 &&
                                                <div className="row">
                                                    <div className="col">LBNR:</div>
                                                    <div className="col">{profile.lbnr}</div>
                                                </div>
                                                }
                                                { user.user_role === 1 &&
                                                <div className="row">
                                                    <div className="col">Steuer-ID:</div>
                                                    <div className="col">{profile.steuer_id}</div>
                                                </div>
                                                }
                                                { user.user_role === 2 &&
                                                <div className="row">
                                                    <div className="col">Sozialamt Aufstockung:</div>
                                                    <div className="col">{ profile.sozialamt_aufstockung === 1 ? 'Ja' : 'Nein' }</div>
                                                </div>
                                                }
                                                { user.user_role === 1 &&
                                                <div className="row">
                                                    <div className="col">Qualifikation:</div>
                                                    <div className="col">{ getQualificationLabel(profile.qualifikation) }</div>
                                                </div>
                                                }
                                            </div>
                                            
                                            <br />

                                            <div className="subtitle">Parameter</div>
                                            <div className="profile-table">
                                                <div className="row">
                                                    <div className="col">Pflegegrad:</div>
                                                    <div className="col">{ProfileField(profile.pflegegrad)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Beweglichkeit:</div>
                                                    <div className="col">{ProfileField(profile.beweglichkeit)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Gesundheitliche Einschränkungen:</div>
                                                    <div className="col">{ProfileField(profile.einschraenkungen)}</div>
                                                </div>
                                                { user.user_role === 2 &&
                                                <div className="row">
                                                    <div className="col">Sonstige Einschränkungen:</div>
                                                    <div className="col">{ profile.sonstige_einschraenkungen ? profile.sonstige_einschraenkungen : '' }</div>
                                                </div>
                                                }
                                                { user.user_role === 2 &&
                                                <div className="row">
                                                    <div className="col">Besserung zu erwarten:</div>
                                                    <div className="col">{ProfileField(profile.besserung)}</div>
                                                </div>
                                                }
                                                <div className="row">
                                                    <div className="col">Sinneswahrnehmung:</div>
                                                    <div className="col">{ProfileField(profile.sinneswahrnehmung)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Geisteszustand:</div>
                                                    <div className="col">{ProfileField(profile.geisteszustand)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Schlafverhalten:</div>
                                                    <div className="col">{ProfileField(profile.schlafverhalten)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Grundpflege:</div>
                                                    <div className="col">{ProfileField(profile.grundpflege)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Hygiene nach Toilettengang:</div>
                                                    <div className="col">{ProfileField(profile.hygiene)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Urinkontrolle:</div>
                                                    <div className="col">{ProfileField(profile.urinkontrolle)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Stuhlkontrolle:</div>
                                                    <div className="col">{ProfileField(profile.stuhlkontrolle)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Hilfe beim Ankleiden:</div>
                                                    <div className="col">{ProfileField(profile.ankleiden)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Hilfe beim Essen:</div>
                                                    <div className="col">{ProfileField(profile.essen)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Wohnsituation:</div>
                                                    <div className="col">{ProfileField(profile.wohnsituation)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Zustand des Haushaltes:</div>
                                                    <div className="col">{ProfileField(profile.wohnzustand)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Wohnort:</div>
                                                    <div className="col">{ProfileField(profile.wohnort)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Haustiere:</div>
                                                    <div className="col">{ProfileField(profile.haustiere)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Welche Haustiere:</div>
                                                    <div className="col">{ProfileField(profile.haustiere_welche)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Einkaufsmöglichkeiten:</div>
                                                    <div className="col">{ProfileField(profile.einkaufsmoeglichkeiten)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Charaktereigenschaften:</div>
                                                    <div className="col">{ProfileField(profile.charaktereigenschaften)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">BK Wohnraum:</div>
                                                    <div className="col">{ProfileField(profile.bk_wohnen)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">BK Aktivitäten:</div>
                                                    <div className="col">{ProfileField(profile.bk_aktivitaeten)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Bevorzugtes Geschlecht:</div>
                                                    <div className="col">{ProfileField(profile.bk_anrede)}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">BK Deutschkenntnisse:</div>
                                                    <div className="col">{ProfileField(profile.bk_deutsch)}</div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">BK Raucher:</div>
                                                    <div className="col">{ProfileField(profile.bk_raucher)}</div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col">BK Führerschein:</div>
                                                    <div className="col">{ProfileField(profile.bk_fuehrerschein)}</div>
                                                </div>
                                            </div>

                                            <br />

                                            <div>

                                                <div className="tabs">
                                                    <div className={freetextLang === 'de' ? 'tab active' : 'tab'} onClick={e => setFreetextLang('de')}>Freitexte { user.user_role === 1 ? 'PL' : 'DE' }</div>
                                                    <div className={freetextLang === 'pl' ? 'tab active' : 'tab'} onClick={e => setFreetextLang('pl')}>Freitexte { user.user_role === 1 ? 'DE' : 'PL'}</div>
                                                </div>

                                                <div className="profile-table">
                                                    { user.user_role === 2 &&
                                                    <div className="row">
                                                        <div className="col">
                                                            Hobbys:<br />
                                                            <strong dangerouslySetInnerHTML={{ __html: getTranslatedText(profile, 'hobbys') }}></strong>
                                                        </div>
                                                    </div>
                                                    }
                                                    { user.user_role === 2 &&
                                                    <div className="row">
                                                        <div className="col">
                                                            Soziales Umfeld:<br />
                                                            <strong dangerouslySetInnerHTML={{ __html: getTranslatedText(profile, 'umfeld') }}></strong>
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className="row">
                                                        <div className="col">
                                                            Sonstiges:<br />
                                                            <strong dangerouslySetInnerHTML={{ __html: getTranslatedText(profile, 'sonstiges') }}></strong>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                }
                                </div>
                            )
                        })}

                    </div>

                    </Window>

                </div>

                <div className="col">

                    { user.notices.filter(n => n.pinned).length > 0 &&
                    <Window name="userPinnedNotices" title="Wichtige Infos">

                        {user.notices.filter(n => n.pinned).map(notice => {
                            let admin = admins.find(admin => admin.id === notice.admin_id)
                            return (
                                <div key={notice.id} className="notice">
                                    <div className="notice_comment">{notice.comment}</div>
                                    <div className="notice_meta">
                                        <div>am {moment(notice.updated_at).format('DD.MM.YYYY')} von {admin ? (admin.vorname + ' ' + admin.nachname) : '[User gelöscht]'}</div>
                                    </div>
                                </div>
                            )
                        })}

                    </Window>
                    }

                    { user.user_role === 1 &&
                    <Window name="userAdditionalData" title="Sonstiges" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ? (
                                <button onClick={e => handleEditAdditionalData()}><FontAwesomeIcon icon={faPen} /> Bearbeiten</button>
                            ) : null }
                        </div>
                    }>

                        <div className="container">
                            <div className="profile-table">
                                <div className="row">
                                    <div className="col">Status:</div>
                                    <div className="col"><Badge value={user.badge} /></div>
                                </div>
                                <div className="row">
                                    <div className="col">Vertrag:</div>
                                    <div className="col">{getContractLabel(user.contract)}</div>
                                </div>
                                <div className="row">
                                    <div className="col">Erfahrung:</div>
                                    <div className="col">{user.experience !== null && (user.experience == 1 ? user.experience + ' Jahr' : user.experience + ' Jahre')}</div>
                                </div>
                                <div className="row" style={{ alignItems: 'center' }}>
                                    <div className="col">Deutsche Sprachkenntnisse:</div>
                                    <div className="col"><LanguageBar value={user.german_skills} /></div>
                                </div>
                                <div className="row">
                                    <div className="col">Impfstatus:</div>
                                    <div className="col">{getImpfstatusLabel(user.impfstatus)}</div>
                                </div>
                                <div className="row">
                                    <div className="col">Freitext</div>
                                </div>
                            </div>
                            <div>{user.description}</div>
                        </div>

                    </Window>
                    }


                    { checkScope('viewPBAddress', { 'user_role': user.user_role }) &&
                    <Window name="userAddress" title="Adresse" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ? (
                                <button onClick={e => handleEditAddress()}><FontAwesomeIcon icon={faPen} /> Bearbeiten</button>
                            ) : null }
                        </div>
                    }>

                            <div className="container">
                                
                                <div className="profile-table">
                                    { user.user_role === 2 &&
                                    <div className="row">
                                        <div className="col">Vertrag</div>
                                        <div className="col">{getContractLabel(user.contract)}</div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className="col">E-Mail:</div>
                                        <div className="col">{user.email}</div>
                                    </div>
                                    { user.user_role === 1 &&
                                    <div className="row">
                                        <div className="col">Handynummer:</div>
                                        <div className="col">{user.mobile}</div>
                                    </div>
                                    }
                                    { user.user_role === 2 &&
                                    <div className="row">
                                        <div className="col">Nettolohn:</div>
                                        <div className="col">{user.wage ? user.wage + ' €' : '-'}</div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className="col">Telefon:</div>
                                        <div className="col">{user.telefon}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Strasse &amp; Hausnummer:</div>
                                        <div className="col">{user.strasse} {user.hausnummer}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">PLZ:</div>
                                        <div className="col">{user.plz}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Ort:</div>
                                        <div className="col">{user.ort}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Land:</div>
                                        <div className="col"><Country iso={user.land} /></div>
                                    </div>
                                </div>

                            </div>

                    </Window>
                    }

                    
                    { checkScope('viewPBContacts', { 'user_role': user.user_role }) &&
                    <Window name="userContacts" title="Ansprechpartner" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('createContact', { 'created_by': user.created_by, 'assigned_to': user.assigned_to }) ? (
                            <button onClick={e => handleAddContact()}><FontAwesomeIcon icon={faPlus} /> Ansprechpartner erstellen</button>
                            ) : null }
                        </div>
                    }>
                            
                        <Table name="tableUserContacts" data={user.contacts} headers={[
                            {
                                field: 'fullname',
                                label: 'Name',
                                type: 'text',
                                size: 'm',
                            },
                            {
                                field: 'kategorie',
                                label: 'Typ',
                                type: 'contactType',
                                size: 'm',
                            },
                        ]} onEdit={handleEditContact} />

                    </Window>
                    }

                </div>

            </div>

            { addProfileModal &&
                <Modal title="Profildaten erstellen" toggle={setAddProfileModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddProfileModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddProfile()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="subtitle">Stammdaten</div>
                    <div className="row">
                        <div className="col">
                            <label>Anrede<br />
                            <Selectbox options={[
                                { label: 'Herr', value: 'Herr' },
                                { label: 'Frau', value: 'Frau' },
                            ]} name="anrede" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Titel<br />
                            <input type="text" value={addProfileInputs.titel} onChange={e => setAddProfileInputs({...addProfileInputs, titel: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Vorname<br />
                            <input type="text" value={addProfileInputs.vorname} onChange={e => setAddProfileInputs({...addProfileInputs, vorname: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Nachname<br />
                            <input type="text" value={addProfileInputs.nachname} onChange={e => setAddProfileInputs({...addProfileInputs, nachname: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Geburtsdatum<br />
                            <input type="date" value={addProfileInputs.geburtsdatum} onChange={e => setAddProfileInputs({...addProfileInputs, geburtsdatum: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Nationalität<br />
                            <input type="text" value={addProfileInputs.nationalitaet} onChange={e => setAddProfileInputs({...addProfileInputs, nationalitaet: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Familienstand<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'familienstand')} name="familienstand" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Raucher<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'raucher')} name="raucher" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Größe<br />
                            <input type="number" value={addProfileInputs.groesse} onChange={e => setAddProfileInputs({...addProfileInputs, groesse: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Gewicht<br />
                            <input type="number" value={addProfileInputs.gewicht} onChange={e => setAddProfileInputs({...addProfileInputs, gewicht: e.target.value})} />
                            </label>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                    

                    <br />
                    <div className="subtitle">Versicherungsdaten</div>
                    { user.user_role === 1 ? (
                    <div>
                        <div className="row">
                            <div className="col">
                                <label>Krankenkasse<br />
                                <input type="text" value={addProfileInputs.krankenkasse} onChange={e => setAddProfileInputs({...addProfileInputs, krankenkasse: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Krankenversicherungsnr.<br />
                                <input type="text" value={addProfileInputs.kranken_vnr} onChange={e => setAddProfileInputs({...addProfileInputs, kranken_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Sozialversicherungsnr.<br />
                                <input type="text" value={addProfileInputs.sozial_vnr} onChange={e => setAddProfileInputs({...addProfileInputs, sozial_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>LBNR<br />
                                <input type="text" value={addProfileInputs.lbnr} onChange={e => setAddProfileInputs({...addProfileInputs, lbnr: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Steuer-ID<br />
                                <input type="text" value={addProfileInputs.steuer_id} onChange={e => setAddProfileInputs({...addProfileInputs, steuer_id: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Qualifikation<br />
                                <Selectbox options={[
                                    { label: 'Hilfskraft', value: 'hilfskraft' },
                                    { label: 'In Ausbildung', value: 'ausbildung' },
                                    { label: 'Geprüfte(r) Alltagsbegleiter*in', value: 'alltagsbegleiter' },
                                    { label: 'Examinierte Pflegefachkraft', value: 'pflegefachkraft' },
                                ]} name="qualifikation" state={addProfileInputs} setState={setAddProfileInputs} />
                                </label>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                    </div>
                    ) : (
                    <div>
                        <div className="row">
                            <div className="col">
                                <label>Krankenkasse<br />
                                <input type="text" value={addProfileInputs.krankenkasse} onChange={e => setAddProfileInputs({...addProfileInputs, krankenkasse: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Krankenkasse Versicherungsart<br />
                                <Selectbox options={[
                                    { label: 'Gesetzlich', value: 'gesetzlich' },
                                    { label: 'Privat', value: 'privat' },
                                ]} name="krankenkasse_typ" state={addProfileInputs} setState={setAddProfileInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Krankenversicherungsnr.<br />
                                <input type="text" value={addProfileInputs.kranken_vnr} onChange={e => setAddProfileInputs({...addProfileInputs, kranken_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Sozialversicherungsnr.<br />
                                <input type="text" value={addProfileInputs.sozial_vnr} onChange={e => setAddProfileInputs({...addProfileInputs, sozial_vnr: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Rentenversicherungsnr.<br />
                                <input type="text" value={addProfileInputs.renten_vnr} onChange={e => setAddProfileInputs({...addProfileInputs, renten_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Sozialamt Aufstockung<br />
                                <Selectbox options={[
                                    { label: 'Nein', value: 0 },
                                    { label: 'Ja', value: 1 },
                                ]} name="sozialamt_aufstockung" state={addProfileInputs} setState={setAddProfileInputs} />
                                </label>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                    </div>
                    )}


                    <br />
                    <div className="subtitle">Parameter</div>
                    <div className="row">
                        <div className="col">
                            <label>Pflegegrad<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'pflegegrad')} name="pflegegrad" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Beweglichkeit<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'beweglichkeit')} name="beweglichkeit" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Gesundheitliche Einschränkungen<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'einschraenkungen')} name="einschraenkungen" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    { user.user_role === 2 &&
                    <div className="row">
                        <div className="col">
                            <label>Sonstige Einschränkungen<br />
                            <input type="text" value={addProfileInputs.sonstige_einschraenkungen} onChange={e => setAddProfileInputs({...addProfileInputs, sonstige_einschraenkungen: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Besserung zu erwarten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'besserung')} name="besserung" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col">
                            <label>Sinneswahrnehmung<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'sinneswahrnehmung')} name="sinneswahrnehmung" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Geisteszustand<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'geisteszustand')} name="geisteszustand" multiple={false} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Schlafverhalten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'schlafverhalten')} name="schlafverhalten" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Grundpflege<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'grundpflege')} name="grundpflege" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hygiene nach Toilettengang<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'hygiene')} name="hygiene" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Urinkontrolle<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'urinkontrolle')} name="urinkontrolle" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Stuhlkontrolle<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'stuhlkontrolle')} name="stuhlkontrolle" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Hilfe beim Ankleiden<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'ankleiden')} name="ankleiden" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hilfe beim Essen<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'essen')} name="essen" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Wohnsituation<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'wohnsituation')} name="wohnsituation" multiple={user.user_role === 1 ? true : false} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Zustand des Haushaltes<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'wohnzustand')} name="wohnzustand" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Wohnort<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'wohnort')} name="wohnort" multiple={user.user_role === 1 ? true : false} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Haustiere<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'haustiere')} name="haustiere" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Welche Haustiere<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'haustiere_welche')} name="haustiere_welche" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Einkaufsmöglichkeiten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'einkaufsmoeglichkeiten')} name="einkaufsmoeglichkeiten" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Charaktereigenschaften<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'charaktereigenschaften')} name="charaktereigenschaften" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>BK Wohnraum<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_wohnen')} name="bk_wohnen" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>BK Aktivitäten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_aktivitaeten')} name="bk_aktivitaeten" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Bevorzugtes Geschlecht<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_anrede')} name="bk_anrede" multiple={true} state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>BK Deutschkenntnisse<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_deutsch')} name="bk_deutsch" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col">
                            <label>BK Raucher<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_raucher')} name="bk_raucher" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div> */}
                        <div className="col">
                            <label>BK Führerschein<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_fuehrerschein')} name="bk_fuehrerschein" state={addProfileInputs} setState={setAddProfileInputs} />
                            </label>
                        </div>
                    </div>

                    { user.user_role === 2 &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <label>Hobbys<br />
                                <textarea value={addProfileInputs.hobbys} onChange={e => setAddProfileInputs({...addProfileInputs, hobbys: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Soziales Umfeld<br />
                                <textarea value={addProfileInputs.umfeld} onChange={e => setAddProfileInputs({...addProfileInputs, umfeld: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Sonstiges<br />
                                <textarea value={addProfileInputs.sonstiges} onChange={e => setAddProfileInputs({...addProfileInputs, sonstiges: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                    }
                    {user.user_role === 1 &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <MaxTextarea max={700} label="Sonstiges" name="sonstiges" state={addProfileInputs} setState={setAddProfileInputs} />
                            </div>
                        </div>
                    </div>
                    }

                </Modal>
            }


            { editProfileModal &&
                <Modal title="Profildaten editieren" toggle={setEditProfileModal} actions={
                    <div className="modal-actions">
                        <button className="deleteLink" onClick={e => handleDeleteProfile()}>Profil löschen</button>
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditProfileModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditProfile()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="subtitle">Stammdaten</div>
                    <div className="row">
                        <div className="col">
                            <label>Anrede<br />
                            <Selectbox options={[
                                { label: 'Herr', value: 'Herr' },
                                { label: 'Frau', value: 'Frau' },
                            ]} name="anrede" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Titel<br />
                            <input type="text" value={editProfileInputs.titel} onChange={e => setEditProfileInputs({...editProfileInputs, titel: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Vorname<br />
                            <input type="text" value={editProfileInputs.vorname} onChange={e => setEditProfileInputs({...editProfileInputs, vorname: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Nachname<br />
                            <input type="text" value={editProfileInputs.nachname} onChange={e => setEditProfileInputs({...editProfileInputs, nachname: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Geburtsdatum<br />
                            <input type="date" value={editProfileInputs.geburtsdatum} onChange={e => setEditProfileInputs({...editProfileInputs, geburtsdatum: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Nationalität<br />
                            <input type="text" value={editProfileInputs.nationalitaet} onChange={e => setEditProfileInputs({...editProfileInputs, nationalitaet: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Familienstand<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'familienstand')} name="familienstand" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Raucher<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'raucher')} name="raucher" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Größe<br />
                            <input type="number" value={editProfileInputs.groesse} onChange={e => setEditProfileInputs({...editProfileInputs, groesse: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Gewicht<br />
                            <input type="number" value={editProfileInputs.gewicht} onChange={e => setEditProfileInputs({...editProfileInputs, gewicht: e.target.value})} />
                            </label>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>


                    <br />
                    <div className="subtitle">Versicherungsdaten</div>
                    { user.user_role === 1 ? (
                    <div>
                        <div className="row">
                            <div className="col">
                                <label>Krankenkasse<br />
                                <input type="text" value={editProfileInputs.krankenkasse} onChange={e => setEditProfileInputs({...editProfileInputs, krankenkasse: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Krankenversicherungsnr.<br />
                                <input type="text" value={editProfileInputs.kranken_vnr} onChange={e => setEditProfileInputs({...editProfileInputs, kranken_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Sozialversicherungsnr.<br />
                                <input type="text" value={editProfileInputs.sozial_vnr} onChange={e => setEditProfileInputs({...editProfileInputs, sozial_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>LBNR<br />
                                <input type="text" value={editProfileInputs.lbnr} onChange={e => setEditProfileInputs({...editProfileInputs, lbnr: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Steuer-ID<br />
                                <input type="text" value={editProfileInputs.steuer_id} onChange={e => setEditProfileInputs({...editProfileInputs, steuer_id: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Qualifikation<br />
                                <Selectbox options={[
                                    { label: 'Hilfskraft', value: 'hilfskraft' },
                                    { label: 'In Ausbildung', value: 'ausbildung' },
                                    { label: 'Geprüfte(r) Alltagsbegleiter*in', value: 'alltagsbegleiter' },
                                    { label: 'Examinierte Pflegefachkraft', value: 'pflegefachkraft' },
                                ]} name="qualifikation" state={editProfileInputs} setState={setEditProfileInputs} />
                                </label>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                    </div>
                    ) : (
                    <div>
                        <div className="row">
                            <div className="col">
                                <label>Krankenkasse<br />
                                <input type="text" value={editProfileInputs.krankenkasse} onChange={e => setEditProfileInputs({...editProfileInputs, krankenkasse: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Krankenkasse Versicherungsart<br />
                                <Selectbox options={[
                                    { label: 'Gesetzlich', value: 'gesetzlich' },
                                    { label: 'Privat', value: 'privat' },
                                ]} name="krankenkasse_typ" state={editProfileInputs} setState={setEditProfileInputs} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Krankenversicherungsnr.<br />
                                <input type="text" value={editProfileInputs.kranken_vnr} onChange={e => setEditProfileInputs({...editProfileInputs, kranken_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Sozialversicherungsnr.<br />
                                <input type="text" value={editProfileInputs.sozial_vnr} onChange={e => setEditProfileInputs({...editProfileInputs, sozial_vnr: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Rentenversicherungsnr.<br />
                                <input type="text" value={editProfileInputs.renten_vnr} onChange={e => setEditProfileInputs({...editProfileInputs, renten_vnr: e.target.value})} />
                                </label>
                            </div>
                            <div className="col">
                                <label>Sozialamt Aufstockung<br />
                                <Selectbox options={[
                                    { label: 'Nein', value: 0 },
                                    { label: 'Ja', value: 1 },
                                ]} name="sozialamt_aufstockung" state={editProfileInputs} setState={setEditProfileInputs} />
                                </label>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                    </div>
                    )}

                    
                    <br />
                    <div className="subtitle">Parameter</div>
                    <div className="row">
                        <div className="col">
                            <label>Pflegegrad<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'pflegegrad')} name="pflegegrad" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Beweglichkeit<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'beweglichkeit')} name="beweglichkeit" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Gesundheitliche Einschränkungen<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'einschraenkungen')} name="einschraenkungen" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    { user.user_role === 2 &&
                    <div className="row">
                        <div className="col">
                            <label>Sonstige Einschränkungen<br />
                            <input type="text" value={editProfileInputs.sonstige_einschraenkungen} onChange={e => setEditProfileInputs({...editProfileInputs, sonstige_einschraenkungen: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Besserung zu erwarten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'besserung')} name="besserung" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col">
                            <label>Sinneswahrnehmung<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'sinneswahrnehmung')} name="sinneswahrnehmung" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Geisteszustand<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'geisteszustand')} name="geisteszustand" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Schlafverhalten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'schlafverhalten')} name="schlafverhalten" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Grundpflege<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'grundpflege')} name="grundpflege" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hygiene nach Toilettengang<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'hygiene')} name="hygiene" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Urinkontrolle<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'urinkontrolle')} name="urinkontrolle" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Stuhlkontrolle<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'stuhlkontrolle')} name="stuhlkontrolle" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Hilfe beim Ankleiden<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'ankleiden')} name="ankleiden" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hilfe beim Essen<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'essen')} name="essen" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Wohnsituation<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'wohnsituation')} multiple={user.user_role === 1 ? true : false} name="wohnsituation" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Zustand des Haushaltes<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'wohnzustand')} name="wohnzustand" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Wohnort<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'wohnort')} name="wohnort" multiple={user.user_role === 1 ? true : false} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Haustiere<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'haustiere')} name="haustiere" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Welche Haustiere<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'haustiere_welche')} name="haustiere_welche" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Einkaufsmöglichkeiten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'einkaufsmoeglichkeiten')} name="einkaufsmoeglichkeiten" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Charaktereigenschaften<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'charaktereigenschaften')} name="charaktereigenschaften" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>BK Wohnraum<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_wohnen')} name="bk_wohnen" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>BK Aktivitäten<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_aktivitaeten')} name="bk_aktivitaeten" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Bevorzugtes Geschlecht<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_anrede')} name="bk_anrede" multiple={true} state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>BK Deutschkenntnisse<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_deutsch')} name="bk_deutsch" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col">
                            <label>BK Raucher<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_raucher')} name="bk_raucher" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div> */}
                        <div className="col">
                            <label>BK Führerschein<br />
                            <Selectbox options={profileOptions.filter(opt => opt.name === 'bk_fuehrerschein')} name="bk_fuehrerschein" state={editProfileInputs} setState={setEditProfileInputs} />
                            </label>
                        </div>
                    </div>
                    { user.user_role === 2 &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <label>Hobbys<br />
                                <textarea value={editProfileInputs.hobbys} onChange={e => setEditProfileInputs({...editProfileInputs, hobbys: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Soziales Umfeld<br />
                                <textarea value={editProfileInputs.umfeld} onChange={e => setEditProfileInputs({...editProfileInputs, umfeld: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Sonstiges<br />
                                <textarea value={editProfileInputs.sonstiges} onChange={e => setEditProfileInputs({...editProfileInputs, sonstiges: e.target.value})}></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                    }
                    { user.user_role === 1 &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <MaxTextarea max={700} label="Sonstiges" name="sonstiges" state={editProfileInputs} setState={setEditProfileInputs} />
                            </div>
                        </div>
                    </div>
                    }

                </Modal>
            }

            { editAdditionalDataModal &&
                <Modal title="Status editieren" toggle={setEditAdditionalDataModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditAdditionalDataModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAdditionalData()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>

                    <div className="row">
                        <div className="col">
                            <label>Status<br />
                            <Selectbox options={[
                                { label: 'Kein Status', value: 0 },
                                { label: 'Bronze-Status', value: 1 },
                                { label: 'Silber-Status', value: 2 },
                                { label: 'Gold-Status', value: 3 },
                            ]} name="badge" state={editAdditionalDataInputs} setState={setEditAdditionalDataInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Vertrag<br />
                            <Selectbox options={user.user_role === 1 ? bkContracts : pbContracts} name="contract" state={editAdditionalDataInputs} setState={setEditAdditionalDataInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Erfahrung (in Jahren)<br />
                            <input type="number" min={0} value={editAdditionalDataInputs.experience} onChange={e => setEditAdditionalDataInputs({ ...editAdditionalDataInputs, experience: e.target.value } )} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Deutsche Sprachkenntnisse<br />
                            <RangeInput min={0} max={10} step={0.5} name="german_skills" state={editAdditionalDataInputs} setState={setEditAdditionalDataInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Impfstatus<br />
                            <Selectbox options={[
                                { label: 'Ungeimpft', value: 1 },
                                { label: 'Geimpft', value: 2 },
                                { label: 'Booster', value: 3 },
                            ]} name="impfstatus" state={editAdditionalDataInputs} setState={setEditAdditionalDataInputs} />
                            </label>
                        </div>
                        <div className="col">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Freitext<br />
                            <textarea value={editAdditionalDataInputs.description} onChange={e => setEditAdditionalDataInputs({ ...editAdditionalDataInputs, description: e.target.value } )}></textarea>
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { editAddressModal &&
                <Modal title="Adresse editieren" toggle={setEditAddressModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditAddressModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditAddress()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    { user.user_role === 1 &&
                    <div className="row">
                        <div className="col">
                            <label>E-Mail<br />
                            <input type="text" value={editAddressInputs.email} onChange={e => setEditAddressInputs({...editAddressInputs, email: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Handynummer<br />
                            <input type="text" value={editAddressInputs.mobile} onChange={e => setEditAddressInputs({...editAddressInputs, mobile: e.target.value.replace(' ', '')})} />
                            </label>
                        </div>
                    </div>
                    }
                    { user.user_role === 2 &&
                    <div className="row">
                        <div className="col">
                            <label>Vertrag<br />
                            <Selectbox options={user.user_role === 1 ? bkContracts : pbContracts} name="contract" state={editAddressInputs} setState={setEditAddressInputs} />
                            </label>
                        </div>
                    </div>
                    }
                    { user.user_role === 2 &&
                    <div className="row">
                        <div className="col">
                            <label>Nettolohn (€)<br />
                            <input type="number" min={0} value={editAddressInputs.wage} onChange={e => setEditAddressInputs({...editAddressInputs, wage: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>E-Mail<br />
                            <input type="text" value={editAddressInputs.email} onChange={e => setEditAddressInputs({...editAddressInputs, email: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col">
                            <label>Strasse<br />
                            <input type="text" value={editAddressInputs.strasse} onChange={e => setEditAddressInputs({...editAddressInputs, strasse: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hausnummer<br />
                            <input type="text" value={editAddressInputs.hausnummer} onChange={e => setEditAddressInputs({...editAddressInputs, hausnummer: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>PLZ<br />
                            <input type="text" value={editAddressInputs.plz} onChange={e => setEditAddressInputs({...editAddressInputs, plz: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Ort<br />
                            <input type="text" value={editAddressInputs.ort} onChange={e => setEditAddressInputs({...editAddressInputs, ort: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Land<br />
                            <Selectbox options={Object.keys(countries).map(code => {
                                return {
                                    label: countries[code],
                                    value: code,
                                }
                            })} name="land" state={editAddressInputs} setState={setEditAddressInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Telefon<br />
                            <input type="text" value={editAddressInputs.telefon} onChange={e => setEditAddressInputs({...editAddressInputs, telefon: e.target.value})} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { addContactModal &&
                <Modal title="Kontaktperson erstellen" toggle={setAddContactModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddContactModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddContact()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>                    
                    
                    <div className="row">
                        <div className="col">
                            <label>Typ<br />
                            <Selectbox options={contactTypes.map(type => ({ label: type.name, value: type.id }) )} name="kategorie" multiple={true} state={addContactInputs} setState={setAddContactInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>E-Mail<br />
                            <input type="email" value={addContactInputs.email} onChange={e => setAddContactInputs({...addContactInputs, email: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Handynummer<br />
                            <input type="text" value={addContactInputs.mobile} onChange={e => setAddContactInputs({...addContactInputs, mobile: e.target.value.replace(' ', '')})} />                            
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Anrede<br />
                            <Selectbox options={[
                                { label: 'Herr', value: 'Herr' },
                                { label: 'Frau', value: 'Frau' },
                            ]} name="anrede" state={addContactInputs} setState={setAddContactInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Titel<br />
                            <input type="text" value={addContactInputs.titel} onChange={e => setAddContactInputs({...addContactInputs, titel: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Vorname<br />
                            <input type="text" value={addContactInputs.vorname} onChange={e => setAddContactInputs({...addContactInputs, vorname: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Nachname<br />
                            <input type="text" value={addContactInputs.nachname} onChange={e => setAddContactInputs({...addContactInputs, nachname: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Strasse<br />
                            <input type="text" value={addContactInputs.strasse} onChange={e => setAddContactInputs({...addContactInputs, strasse: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hausnummer<br />
                            <input type="text" value={addContactInputs.hausnummer} onChange={e => setAddContactInputs({...addContactInputs, hausnummer: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>PLZ<br />
                            <input type="text" value={addContactInputs.plz} onChange={e => setAddContactInputs({...addContactInputs, plz: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Ort<br />
                            <input type="text" value={addContactInputs.ort} onChange={e => setAddContactInputs({...addContactInputs, ort: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Land<br />
                            <Selectbox options={Object.keys(countries).map(code => {
                                return {
                                    label: countries[code],
                                    value: code,
                                }
                            })} name="land" state={addContactInputs} setState={setAddContactInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Telefon<br />
                            <input type="text" value={addContactInputs.telefon} onChange={e => setAddContactInputs({...addContactInputs, telefon: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop:30 }}>
                        <div className="col">
                            <label>
                                <input type="checkbox" value={1} onChange={e => setAddContactInputs({...addContactInputs, account: e.target.checked ? 1 : 0})} />
                                Die E-Mail Adresse dieser Person als Account-Login verwenden
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { editContactModal &&
                <Modal title="Kontaktperson editieren" toggle={setEditContactModal} actions={
                    <div className="modal-actions">
                        { (admin.role == 1 && !contactIsAccount) ? <button className="deleteLink" onClick={e => handleDeleteContact()}>Kontaktperson löschen</button> : null }
                        { contactIsAccount && <span className="blueText">Diese E-Mail wird momentan als Account-Login verwendet</span> }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditContactModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditContact()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    
                    <div className="row">
                        <div className="col">
                            <label>Typ<br />
                            <Selectbox options={contactTypes.map(type => {
                                return {
                                    label: type.name,
                                    value: type.id,
                                }
                            })} name="kategorie" multiple={true} state={editContactInputs} setState={setEditContactInputs} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>E-Mail<br />
                            <input type="email" value={editContactInputs.email} onChange={e => setEditContactInputs({...editContactInputs, email: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Handynummer<br />
                            <input type="text" value={editContactInputs.mobile} onChange={e => setEditContactInputs({...editContactInputs, mobile: e.target.value.replace(' ', '')})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Anrede<br />
                            <Selectbox options={[
                                { label: 'Herr', value: 'Herr' },
                                { label: 'Frau', value: 'Frau' },
                            ]} name="anrede" state={editContactInputs} setState={setEditContactInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Titel<br />
                            <input type="text" value={editContactInputs.titel} onChange={e => setEditContactInputs({...editContactInputs, titel: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Vorname<br />
                            <input type="text" value={editContactInputs.vorname} onChange={e => setEditContactInputs({...editContactInputs, vorname: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Nachname<br />
                            <input type="text" value={editContactInputs.nachname} onChange={e => setEditContactInputs({...editContactInputs, nachname: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Strasse<br />
                            <input type="text" value={editContactInputs.strasse} onChange={e => setEditContactInputs({...editContactInputs, strasse: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Hausnummer<br />
                            <input type="text" value={editContactInputs.hausnummer} onChange={e => setEditContactInputs({...editContactInputs, hausnummer: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>PLZ<br />
                            <input type="text" value={editContactInputs.plz} onChange={e => setEditContactInputs({...editContactInputs, plz: e.target.value})} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Ort<br />
                            <input type="text" value={editContactInputs.ort} onChange={e => setEditContactInputs({...editContactInputs, ort: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Land<br />
                            <Selectbox options={Object.keys(countries).map(code => {
                                return {
                                    label: countries[code],
                                    value: code,
                                }
                            })} name="land" state={editContactInputs} setState={setEditContactInputs} />
                            </label>
                        </div>
                        <div className="col">
                            <label>Telefon<br />
                            <input type="text" value={editContactInputs.telefon} onChange={e => setEditContactInputs({...editContactInputs, telefon: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    { !contactIsAccount &&
                    <div className="row" style={{ marginTop:30 }}>
                        <div className="col">
                            <label>
                                <input type="checkbox" value={1} onChange={e => setEditContactInputs({...editContactInputs, account: e.target.checked ? 1 : 0})} />
                                Die E-Mail Adresse dieser Person als Account-Login verwenden
                            </label>
                        </div>
                    </div>
                    }

                </Modal>
            }

        </div>
    )

}

export default ProfileDataScreen

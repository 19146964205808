import React, { useState, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import '../../App.css'
import Logo from '../../images/logo-white.svg'
import LoadingIcon from '../../images/loading.svg'

import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { axios } from '../../scripts/Axios'

function LoginScreen() {

    const { setUser, setToken } = useContext(UserContext)
    const { loading, setLoading } = useContext(AppContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)

    useEffect(() => setLoading(false), [])

    const login = () => {

        setLoading(true)

        axios.post('login', {
            email: email,
            password: password,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setErrorMsg(response.data.message)
            }else{
                setUser(response.data.user)
                setToken(response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                localStorage.setItem('token', response.data.token)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    const handleKeys = (e) => {
        if(e.key === 'Enter'){
            login()
        }
    }

    return (
        <div id="login-wrapper">
            <div id="login-container">
                <img className="logo" src={Logo} alt="Logo" />
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} onKeyPress={e => handleKeys(e)} placeholder="E-Mail" />
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} onKeyPress={e => handleKeys(e) } placeholder="Passwort" />
                <button onClick={e => login()}>{ loading ? <img src={LoadingIcon} alt="Loading" /> : 'Login' }</button>
                <NavLink className="light-link" exact={true} to="/forgot-password" title="Passwort vergessen">Passwort vergessen?</NavLink>

                { errorMsg && <div className="message error">{ errorMsg }</div> }
                { successMsg && <div className="message success">{ successMsg }</div> }
            </div>
        </div>
    )

}

export default LoginScreen
import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API,
})

const authAxios = Axios.create({
    baseURL: process.env.REACT_APP_API,
})

authAxios.interceptors.request.use(config => {
    let token = localStorage.getItem('token')
    config.headers = {
        'Authorization': 'Bearer ' + token,
    }
    return config
}, error => {
    console.log(error.response.data)
})


authAxios.interceptors.response.use((response) => {
    return response
}, error => {
    if (401 === error.response.status) {

        console.log({
            title: "Session Expired",
            text: "Your session has expired. Would you like to be redirected to the login page?",
        })
        
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        window.location.href = '/'

    }
})


export {axios, authAxios}
import React, { useState, useContext, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import '../../App.css'
import LoadingIcon from '../../images/loading.svg'

import { AppContext } from '../../scripts/AppContext'
import { axios } from '../../scripts/Axios'

function ResetPassword() {

    const location = useLocation()
    const data = queryString.parse(location.search)

    const { loading, setLoading } = useContext(AppContext)

    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [errorMsg, setErrorMsg] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)

    useEffect(() => setLoading(false), [])

    function resetPassword() {

        setLoading(true)
        setErrorMsg(null)
        setSuccessMsg(null)

        axios.post('reset-password', {
            email: data.email,
            token: data.token,
            password: password,
            password_confirmation: passwordConfirmation,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setErrorMsg(response.data.message)
            }else{
                setSuccessMsg(response.data.message)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleKeys(e) {
        if(e.key === 'Enter'){
            resetPassword()
        }
    }

    return (
        <div id="login-wrapper">
            <div id="login-container">
                <small style={{ color: '#9eaeb7' }}>Passwortanforderungen: min. 8 Zeichen, davon min. 1 Buchstabe, 1 Zahl und 1 Sonderzeichen</small><br />
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} onKeyPress={e => handleKeys(e) } placeholder="Neues Passwort" />
                <input type="password" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} onKeyPress={e => handleKeys(e) } placeholder="Neues Passwort wiederholen" />
                <button onClick={e => resetPassword()}>{ loading ? <img src={LoadingIcon} alt="Loading" /> : 'Passwort speichern' }</button>
                <NavLink className="light-link" exact={true} to="/" title="Login">Zum Login</NavLink>

                { errorMsg && <div className="message error">{ errorMsg }</div> }
                { successMsg && <div className="message success">{ successMsg }</div> }
            </div>
        </div>
    )

}

export default ResetPassword
import React from 'react'
import '../App.css'

function CareStatus({value}) {

    const states = [
        {
            className: 'new',
            label: 'Planung',
        },
        {
            className: 'locked',
            label: 'Storniert ',
        },
        {
            className: 'active',
            label: 'Bestätigt',
        },
        {
            className: 'prepared',
            label: 'Vorbereitung',
        },
        {
            className: 'inactive',
            label: 'Vergangen',
        },
    ]

    return states[value] ? (
        <div className={'user-status ' + states[value].className}>
            {states[value].label}
        </div>
    ) : null

}

export default CareStatus
import React, { useContext, useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'

import { AppContext } from '../scripts/AppContext'
import { authAxios } from '../scripts/Axios'

import Selectbox from './Selectbox'
import Document from './Document'
import Modal from './Modal'

function DocumentUploader({user, setUser, categories}) {

    const { setLoading } = useContext(AppContext)

    const [category, setCategory] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteID, setDeleteID] = useState(null)

    function handleUpload(files){

        if(!category){
            alert('Bitte wählen Sie zuerst eine Kategorie aus.')
            return
        }

        setLoading(true)

        let data = new FormData()
        data.append('user_id', user.id)
        data.append('kategorie', category)
        files.map((file, index) => {
            data.append('files[' + index + ']', file)
        })
        
        authAxios.post('document', data)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUser({
                    ...user,
                    documents: [
                        ...response.data.documents,
                        ...user.documents,
                    ]
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function checkCategory(e){
        if(!category){
            e.stopPropagation()
            alert('Bitte wählen Sie zuerst eine Kategorie aus.')
            return
        }
    }


    function handleDelete(id){
        setDeleteID(id)
        setDeleteModal(true)
    }


    function deleteFile(id){

        setDeleteModal(false)
        
        authAxios.delete('document/' + id)
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setUser({
                    ...user,
                    documents: user.documents.filter(document => document.id !== id)
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <div>
            
            <div className="uploader">

                <Selectbox placeholder="Kategorie wählen ..." options={categories.map(cat => ({label: cat.name, value: cat.slug}))} state={category} setState={setCategory} />

                <br />

                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps({
                            onClick: e => checkCategory(e)
                        })}>
                            <input {...getInputProps()} />
                            <p>Hier klicken oder Dateien hineinziehen um Medien hochzuladen</p>
                            <p>
                                <small>Erlaubte Formate: pdf, doc, docx, xls, xlsx</small>
                            </p>
                        </div>
                    </section>
                )}
                </Dropzone>

                {categories.map(cat => (
                    <div>
                        <div className="subtitle">{cat.name}</div>
                        <div className="document-list">
                            { user.documents &&
                            user.documents.filter(document => document.kategorie === cat.slug).map((document, index) => {
                                return <Document key={index} index={index} file={document} editable={true} handleDelete={handleDelete} />
                            })
                            }
                        </div>
                    </div>
                ))}

            </div>

            { deleteModal &&
                <Modal title="Sicher?" toggle={setDeleteModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setDeleteModal(false)}>Abbrechen</button>
                            <button className="deleteButton" onClick={e => deleteFile(deleteID)}><FontAwesomeIcon icon={faMinusCircle} /> Löschen</button>
                        </div>
                    </div>
                }>
                    Möchten Sie dieses Dokument wirklich endgültig löschen?
                </Modal>
            }

        </div>
    )

}

export default DocumentUploader
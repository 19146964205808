import React from 'react'
import '../App.css'

function UserStatus({value}) {

    const states = [
        {
            className: 'inactive',
            label: 'Verfügbar',
        },
        {
            className: 'active',
            label: 'In Betreuung',
        },
        {
            className: 'pausing', // update class!
            label: 'Pausierend ',
        },
    ]

    return states[value] ? (
        <div className={'user-status ' + states[value].className}>
            {states[value].label}
        </div>
    ) : null

}

export default UserStatus
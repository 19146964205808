import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import '../App.css'
import { authAxios } from '../scripts/Axios'
import Modal from './Modal'

function LeistungsnachweisStatus({ values }) {

    const [status, setStatus] = useState(values.status)
    const states = [
        {
            className: 'open',
            label: 'Nicht erhalten',
        },
        {
            className: 'confirmed',
            label: 'Erhalten',
        },
    ]

    const [showModal, setShowModal] = useState(false)

    function updateStatus(id, newStatus){
        
        authAxios.put('leistungsnachweis/' + id + '/status', { status: newStatus })
        .then(response => {

            if(response.data.type !== 'error'){
                setShowModal(false)
                setStatus(newStatus)
                // leistungsnachweise updaten! sonst passieren komische dinge
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <div>

            <div className={'confirm-status ' + states[status].className} onClick={() => setShowModal(true)}>
                {states[status].label}
            </div>

            { showModal &&
                <Modal title="Erhaltstatus ändern" toggle={setShowModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setShowModal(false)}>Abbrechen</button>
                            <button className="deleteButton" onClick={() => updateStatus(values.id, 0)}><FontAwesomeIcon icon={faTimes} /> Nicht erhalten</button>
                            <button className="saveButton" onClick={() => updateStatus(values.id, 1)}><FontAwesomeIcon icon={faCheck} /> Erhalten</button>
                        </div>
                    </div>
                }>
                    
                    <p>Hier können Sie den Erhalt des Leistungsnachweises bestätigen.</p>

                </Modal>
            }

        </div>
    ) 

}

export default LeistungsnachweisStatus
import React, { useEffect, useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons'
import { authAxios } from '../scripts/Axios'
import { useHistory } from 'react-router-dom'

function Media({file, index, flex, editable, handleDelete, showLightbox, square = true, type = 'upload', linkTo = null}) {

    const history = useHistory()

    const [styles, setStyles] = useState({
        flex: flex ? flex : '0 0 calc(20% - 1px)',
        cursor: showLightbox || editable ? 'pointer' : 'default',
        opacity: 0,
        transition: 'opacity .4s ease',
    })
    const classNames = square ? 'media ' : 'media-full ' + file.kategorie
    const [url, setUrl] = useState()

    useEffect(() => {
        loadImage()
    }, [file])

    const loadImage = async () => {

        let endpoint
        let id = file.id
        let image = null

        if(type == 'chat'){
            id = file.id || file
            image = await authAxios.get('/chat-upload/' + id, {responseType: 'blob', timeout: 30000})
        }
        else if(type == 'document'){
            image = await authAxios.get('/document/' + id + '/file', {responseType: 'blob', timeout: 30000})
        }
        else if(type == 'upload'){
            endpoint = file.typ.startsWith('video') ? '/thumbnail' : '/upload'
            image = await authAxios.get(endpoint  + '/' + id, {responseType: 'blob', timeout: 30000})
        }

        if(image.status === 200){
            let url = URL.createObjectURL(image.data)
            setUrl(url)
            setStyles({...styles, opacity: 1, backgroundImage: 'url(' + url + ')'})
        }
    }

    function clickImage(){
        if(linkTo){
            history.push(linkTo)
        }
        else if(showLightbox){
            showLightbox(index)
        }
    }

    return ( 
        <div onClick={clickImage} className={classNames} style={styles}>
        { editable &&
            <div className="deleteMedia" onClick={e => handleDelete(file.id)}>
                <FontAwesomeIcon icon={faMinusCircle} />
            </div>   
        }
        { !square && url ? (<a href={url} download={true} className="download-media-link"><FontAwesomeIcon icon={faDownload} /> Bild herunterladen</a>) : null }
        </div>
    )

}

export default Media
import React from 'react'
import '../App.css'

function RequestStatus({value}) {

    const states = [
        {
            className: 'open',
            label: 'Offen',
        },
        {
            className: 'declined',
            label: 'Abgelehnt',
        },
        {
            className: 'accepted',
            label: 'Akzeptiert',
        },
        {
            className: 'timedout',
            label: 'Abgelaufen',
        },
    ]

    return states[value] ? (
        <div className={'request-status ' + states[value].className}>
            {states[value].label}
        </div>
    ) : null

}

export default RequestStatus
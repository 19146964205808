import React, { useContext, useState } from 'react'
import '../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import Window from '../../components/Window'
import Modal from '../../components/Modal'
import Media from '../../components/Media'
import Document from '../../components/Document'
import Lightbox from '../../components/Lightbox'
import Uploader from '../../components/Uploader'
import DocumentUploader from '../../components/DocumentUploader'
import DocumentLightbox from '../../components/DocumentLightbox'
import { UserContext } from '../../scripts/UserContext'

function MediaDocumentsScreen({user, setUser}) {

    const { checkScope } = useContext(UserContext)

    const [editMediaModal, setEditMediaModal] = useState(false)
    const [showLightbox, setShowLightbox] = useState(false)
    const [lightboxIndex, setLightboxIndex] = useState(0)

    const [editDocumentsModal, setEditDocumentsModal] = useState(false)
    const [showDocumentLightbox, setShowDocumentLightbox] = useState(false)
    const [viewDocument, setViewDocument] = useState(null)

    const categories = user.user_role === 1 ? [
        { slug: 'vertrag', name: 'Verträge' },
        { slug: 'referenz', name: 'Referenzen' },
        { slug: 'lohnabrechnung', name: 'Lohnabrechnungen' },
        { slug: 'sonstiges', name: 'Sonstiges' },
    ] : [
        { slug: 'vertrag', name: 'Verträge' },
        { slug: 'rechnung', name: 'Rechnungen' },
        { slug: 'mahnung', name: 'Mahnungen' },
        { slug: 'sonstiges', name: 'Sonstiges' },
    ]

    function toggleLightbox(index){
        setLightboxIndex(index)
        setShowLightbox(true)
    }

    function toggleDocumentLightbox(file){
        setViewDocument(file)
        setShowDocumentLightbox(true)
    }

    return (
        <div className="page-content">

            { showLightbox &&
            <Lightbox files={user.media} index={lightboxIndex} onClose={() => setShowLightbox(false)} />
            }

            { showDocumentLightbox &&
            <DocumentLightbox file={viewDocument} onClose={() => setShowDocumentLightbox(false)} />
            }

            <div className="window-row">

                <div className="col">

                    <Window name="userMedia" title="Medien" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ? (
                                <button onClick={e => setEditMediaModal(true)}><FontAwesomeIcon icon={faPen} /> Bearbeiten</button>
                            ) : null }
                        </div>
                    }>
                        
                        <div className="container">

                            <div className="subtitle">Bilder der Person</div>
                            <div className="media-grid">
                                { user.media &&
                                user.media.filter(media => media.kategorie === 'person').map((media, index) => {
                                    return <Media key={index} index={index} file={media} showLightbox={toggleLightbox} />
                                })
                                }
                            </div>


                            { user.user_role === 2 &&
                            <div>
                                <br />
                                <div className="subtitle">Bilder der Wohnung</div>
                                <div className="media-grid">
                                    { user.media &&
                                    user.media.filter(media => media.kategorie === 'wohnung').map((media, index) => {
                                        return <Media key={index} index={index} file={media} showLightbox={toggleLightbox} />
                                    })
                                    }
                                </div>
                            </div>
                            }

                            { user.user_role === 2 &&
                            <div>
                                <br />
                                <div className="subtitle">Videos der Wohnung</div>
                                <div className="media-grid">
                                    { user.media &&
                                    user.media.filter(media => media.kategorie === 'video').map((media, index) => {
                                        return <Media key={index} index={index} file={media} showLightbox={toggleLightbox} />
                                    })
                                    }
                                </div>
                            </div>
                            }

                        </div>

                    </Window>

                </div>

                <div className="col">

                    <Window name="userDocuments" title="Dokumente" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id }) ? (
                                <button onClick={e => setEditDocumentsModal(true)}><FontAwesomeIcon icon={faPen} /> Bearbeiten</button>
                            ) : null }
                        </div>
                    }>
                        
                    <div className="container">


                        {categories.map((cat, index0) => (
                            <div key={'cat'+index0}>                            
                                <div className="subtitle">{cat.name}</div>
                                <div className="document-list">
                                    { user.documents &&
                                    user.documents.filter(document => document.kategorie === cat.slug).map((document, index) => {
                                        return <Document key={'doc'+index} index={index} file={document} showLightbox={toggleDocumentLightbox} />
                                    })
                                    }
                                </div>
                            </div>
                        ))}

                    </div>

                    </Window>

                </div>

            </div>

            { editMediaModal &&
            <Modal title="Medien editieren" toggle={setEditMediaModal} actions={
                <div className="modal-actions">
                    <div style={{marginLeft:'auto'}}>
                        <button className="cancelButton" onClick={e => setEditMediaModal(false)}>Schliessen</button>
                    </div>
                </div>
            }>
                <Uploader user={user} setUser={setUser} />
            </Modal>
            }

            { editDocumentsModal &&
            <Modal title="Dokumente editieren" toggle={setEditDocumentsModal} actions={
                <div className="modal-actions">
                    <div style={{marginLeft:'auto'}}>
                        <button className="cancelButton" onClick={e => setEditDocumentsModal(false)}>Schliessen</button>
                    </div>
                </div>
            }>
                <DocumentUploader user={user} setUser={setUser} categories={categories} />
            </Modal>
            }

        </div>
    )

}

export default MediaDocumentsScreen
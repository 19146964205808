import React, { useContext, useEffect, useState } from 'react'
import '../App.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { authAxios } from '../scripts/Axios'

import Window from '../components/Window'
import Table from '../components/Table'
import Alert from '../components/Alert'

import { AppContext } from '../scripts/AppContext'


function CommunicationLogsScreen() {

    const { setLoading } = useContext(AppContext)

    const [failedMessages, setFailedMessages] = useState([])

    useEffect(() => {

        setLoading(true)

        authAxios.get('failed-messages')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setFailedMessages(response.data.jobs)
            }

        })
        .catch(error => {
            console.log(error)
        })

    }, [])


    return (
        <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page">
            <div className="page-content">

                <div className="window-row">
                    <div className="col">

                        <Window name="failedMessages" title="Fehlgeschlagene Nachrichten">

                            <Table name="tableCommunicationLogs" data={failedMessages} headers={[
                                {
                                    field: 'failed_at',
                                    label: 'Datum',
                                    type: 'datetime',
                                    size: 'm',
                                },
                                {
                                    field: 'exception',
                                    label: 'Exception',
                                    type: 'text',
                                    size: 'xxl',
                                },
                                {
                                    field: 'payload',
                                    label: 'Payload',
                                    type: 'payload',
                                    size: 'xxl',
                                },
                            ]} perPage={25} />

                        </Window>

                    </div>
                </div>

            </div>
        </Scrollbars>
    )

}

export default CommunicationLogsScreen
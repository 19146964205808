import React, { useContext } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faDownload, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { DataContext } from '../scripts/DataContext'
import { SizeFormat } from '../scripts/Functions'
import { authAxios } from '../scripts/Axios'


function Document({index, file, editable, handleDelete, attachment = false, showLightbox}) {

    const { admins, fileIcons } = useContext(DataContext)

    let admin = admins.find(admin => admin.id === file.von)
    let icon = fileIcons.find(f => f.types.includes(file.typ)) || fileIcons[0]

    async function downloadDocument(){

        let endpoint = attachment ? '/attachment/' + file.id : '/document/' + file.id + '/file'
        let doc = await authAxios.get(endpoint, {responseType: 'blob', timeout: 30000})
        if(doc.status === 200){
            let link = document.createElement('a')
            link.href = URL.createObjectURL(doc.data)
            link.setAttribute('download', file.name)
            document.body.appendChild(link)
            link.click()
        }

    }

    return (
        <div key={index} className="doc">
            <img className="docImage" src={ icon.src } />
            <div>
                <div className="docTitle">{ file.name }</div>
                <div className="docMeta">
                    <span>{ moment(file.created_at).format('DD.MM.YYYY') }</span>
                    <span>{ SizeFormat(file.groesse) }</span>
                    { admin && <span>von { admin.vorname + ' ' + admin.nachname }</span> }
                </div>
            </div>
            { !editable &&
                <div style={{ marginLeft: 'auto', flexShrink: 0}}>
                    <button className="viewButton" onClick={() => {
                        if(showLightbox){ showLightbox(file) }
                    }}>
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button className="downloadButton" onClick={downloadDocument}>
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                </div>
            }
            { editable &&
                <div style={{ marginLeft: 'auto', flexShrink: 0}}>
                    <button className="deleteButton" onClick={e => handleDelete(file.id)}>
                        <FontAwesomeIcon icon={faMinusCircle} />
                    </button>   
                </div>
            }
        </div>
    )

}

export default Document